import React, { useState, useEffect, Dispatch } from "react";
import { Drawer, IconButton, List, Theme } from "@material-ui/core";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  Feedback as FeedbackIcon,
  DraftsOutlined,
 
} from "@material-ui/icons";
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar, showFeedbackModal } from "../../actions/layout";
import { LayoutAction } from "../../model";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "My Uploads",
    link: "/app/dashboard?sort=myUploads",
    icon: <BackupOutlinedIcon />,
  },
  {
    id: 2,
    label: "My Likes",
    link: "/app/dashboard?sort=myLikes",
    icon: <FavoriteBorderIcon  />,
  },
  {
    id: 3,
    label: "Shared with me",
    link: "/app/dashboard?sort=myShare",
    icon: <ShareOutlinedIcon />,
  },
  {
    id: 4,
    label: "Drafts",
    link: "/app/dashboard?sort=draft",
    icon: <DraftsOutlined />,
  },
];

interface IProps {
  location: any;
}

function Sidebar({ location }: IProps) {
  var classes: any = useStyles();
  var theme = useTheme<Theme>();

  // global
  const { isSidebarOpened } = useSelector((state: RootState) => state.layout);
  const dispatch = useDispatch<Dispatch<LayoutAction>>();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      PaperProps={{ elevation: 0 }}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => dispatch(toggleSidebar())}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened} 
            {...link}
          />
          
        ))}
        <div
          style={{ marginTop: "auto" }}
          onClick={() => dispatch(showFeedbackModal())}
        >
          <SidebarLink
            key="feedback"
            label="Feedback"
            location={location}
            isSidebarOpened={isSidebarOpened}
            icon={<FeedbackOutlinedIcon />}
          />
        </div>
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
