import { ProjectState, ProjectAction, ProjectActions } from "../model/project";
import { Reducer } from "react";

const initialState: ProjectState = {
  allProjects: [],
  filter:null,
  draftProjects: [],
  creating: false,
  fetching: true,
  sharing: false,
  shared: false,
  deleting: false,
  deleted: false,
  nextPageToken: null, // New token for pagination
  hasMore: true,       // Indicates if there are more pages to fetch
  previousFilter:null,
  previousSortBy:null,
  previousSortType:null

  
};

export const projectReducer: Reducer<ProjectState, ProjectAction> = (
  state: ProjectState = initialState,
  action: ProjectAction
): ProjectState => {
  switch (action.type) {
    case ProjectActions.CREATE:
      return { ...state, creating: true, createError: undefined };

    case ProjectActions.CREATE_SUCCESS:
      return { ...state, creating: false, createError: undefined };

    case ProjectActions.CREATE_ERROR:
      return { ...state, creating: false, createError: action.payload };

    case ProjectActions.SHARE:
      return { ...state, sharing: true };

    case ProjectActions.SHARED_PROJECT:
    case ProjectActions.SHARED_REPORT:
      return { ...state, sharing: false, shared: true };

    case ProjectActions.SHARE_ERROR:
      return { ...state, sharing: false, shareError: action.payload };

      case ProjectActions.FETCH:
        return {
          ...state,
          fetching: true,
          fetchError: undefined,
        };

        case ProjectActions.FETCHED_ALL: {
          const hasFilterChanged = state.previousFilter !== action.payload?.filter;
          const hasSortChanged = state.previousSortBy !== action.payload?.sortBy;
          const hasSortTypeChanged = state.previousSortType != action.payload?.sortingType

          console.debug("hasQueryChanged, filter", state.previousFilter, action.payload?.filter)
          console.debug("hasQueryChanged, sortBy", state.previousSortBy, action.payload?.sortBy)
          console.debug("hasQueryChanged, sortType", state.previousSortType, action.payload?.sortingType)

          console.debug("hasQueryChanged, hasFilterChanged", hasFilterChanged)

          console.debug("hasQueryChanged, hasSortChanged", hasSortChanged)
          console.debug("hasQueryChanged, hasSortTypeChanged", hasSortTypeChanged)
          
          // Determine if the filter or sort criteria changed
          const hasQueryChanged = hasFilterChanged || hasSortChanged || hasSortTypeChanged;
          console.debug("hasQueryChanged, hasQueryChanged", hasQueryChanged)
          return {
            ...state,
            // Clear project list if filter or sort changed, otherwise append
            allProjects: hasQueryChanged
              ? [...(action.payload?.projects || [])]  // Reset projects if query (filter/sort) changed
              : [...state.allProjects, ...(action.payload?.projects || [])], // Append if query is the same
              
            // Update pagination token and related states
            nextPageToken: action.payload?.nextPageToken || null,
            hasMore: action.payload?.hasMore || false,
            fetching: action.payload?.loading ?? false,
            fetchError: action.payload?.error,
        
            // Save current filter and sort criteria for future comparisons
            previousFilter: action.payload?.filter,
            previousSortBy: action.payload?.sortBy,
            previousSortType:action.payload?.sortingType
          };
        }
        
      
    case ProjectActions.FETCH_DRAFT:
      return { ...state, fetching: true, fetchError: undefined };

    case ProjectActions.FETCHED_DRAFT:
      return {
        ...state,
        fetching: false,
        fetchError: undefined,
        draftProjects: action.payload ?? [],
      };

    case ProjectActions.FETCHED_REPORT:
      return {
        ...state,
        fetching: false,
        fetchError: undefined,
        focusedReport: action.payload,
      };

      case ProjectActions.FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        fetchError: action.payload?.error,
      };

    case ProjectActions.DELETE:
      return { ...state, deleting: true };

    case ProjectActions.DELETED_PROJECT:
      const newListOfProjects = state.allProjects.filter(
        (p) => p.id !== action.payload
      );
      return {
        ...state,
        deleted: true,
        deleting: false,
        allProjects: newListOfProjects,
        focusedReport: undefined,
      };

    case ProjectActions.RESET_MODAL:
      return {
        ...state,
        deleting: false,
        deleteError: undefined,
        deleted: false,
        sharing: false,
        shareError: undefined,
        shared: false,
      };

    case ProjectActions.ADD_FAVOURITE:
      const newListOfProjectsAddFavourite = state.allProjects.map((p) => {
        if (p.id == action.payload) {
          return {
            ...p,
            likes: true,
            likesCount: p.likesCount + 1,
            addFavouriteError: undefined,
          };
        }
        return p;
      });
      return {
        ...state,
        allProjects: newListOfProjectsAddFavourite,
      };

    case ProjectActions.ADD_FAVOURITE_ERROR:
      return { ...state, addFavouriteError: action.payload };

    case ProjectActions.REMOVE_FAVOURITE:
      const newListOfProjectsRemoveFavourite = state.allProjects.map((p) => {
        const removeCount = p.likesCount - 1;
        if (p.id == action.payload) {
          return {
            ...p,
            likes: false,
            likesCount: removeCount,
            removeFavouriteError: undefined,
          };
        }
        return p;
      });
      return {
        ...state,
        allProjects: newListOfProjectsRemoveFavourite,
      };

    case ProjectActions.REMOVE_FAVOURITE_ERROR:
      return { ...state, removeFavouriteError: action.payload };

    default:
      return state;
  }
};
