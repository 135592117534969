import React, { useState, Dispatch, useEffect } from "react";
import {
  AppBar, Toolbar, IconButton, Menu, MenuItem, Avatar, Button, Divider,
} from "@material-ui/core";
import {
  NotificationsNone as NotificationsIcon,
} from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import { searchString } from "../../actions/search";
// styles
import useStyles from "./styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// components
import { Badge, Typography } from "../Wrappers/Wrappers";
import icon from "../../images/download.png"
import {  signOutUser } from "../../actions/user";
import { useDispatch, useSelector } from "react-redux";
import { LayoutAction, UserDispatch, SearchAction } from "../../model";
import { RootState } from "../../reducers";
// import debounce from 'lodash.debounce';
import {
  // getImageLabel,
  sectionLabel
} from "../../pages/create/components/data";
import { getUserNotification } from "../../service/getUserNotification.service";
import { readNotification } from "../../service/readNotification.service";

function Header(props: any) {
  const classes = useStyles();
  let history = useHistory()

  // global
  const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
  const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
  const dispatchUser = useDispatch<UserDispatch>();
  const user = useSelector((state: RootState) => state.firebase.auth);
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const payment = useSelector((state: RootState) => state.user);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [previousData, setPreviousData] = useState([])
  const [hideShowMore, setHideShowMore] = useState(false)
  const [flag, setFlag] = useState(false);
  const [reload, setReload] = useState(false)
  const hasPlan =
    profile.token?.claims.stripeRole &&
    ["pro", "starter", "enterprise"].includes(profile.token.claims.stripeRole);
  const [numUnread, setUnRead] = useState(0);

  const [notificationData, setNotificationData] = useState([])
  const [notificationsMenu, setNotificationsMenu] = useState<
    EventTarget & HTMLButtonElement
  >();
  const [, setIsNotificationsUnread] = useState(true);
  const [profileMenu, setProfileMenu] = useState<
    EventTarget & HTMLButtonElement
  >();

  console.debug("asxhjhj", profile)

  useEffect(function () {
    // console.debug("Api reload ho gyi")
      getUserNotification(user.uid, 10, page, profile).then(response => {
        // console.debug("getUserNotification :line 69", response )
        let data = response?.data?.data
        let metaTotal = response?.data?.metaTotal
        let statusCount = response?.data?.notificationStatus
        setReload(false)
        if (data) {
          // console.debug("getUserNotification :line 74" )
          previousNotificationData(notificationData, data)
          // setFlag(false)
        }
        notificationDataCount(data, metaTotal, statusCount)
        if (metaTotal?.totalPages == page) {
          setHideShowMore(true)
        }

      })
  }, [page ||reload == true])

  function previousNotificationData(previousData1, currentData) {
    let array = [...previousData1, ...currentData]
    setNotificationData(array)
  }

  function notificationDataCount(data, metaTotal, statusCount) {
    // console.debug("getUserNotification :line 92" )
    if (metaTotal) {
      setPage(metaTotal.currentPage)
      setTotalCount(metaTotal.totalCount)
      setTotalPageCount(metaTotal.totalPage)
    }
    if (statusCount) {
      // console.debug("getUserNotification :line 99" )
      setUnRead(0)
      setUnRead(statusCount.unread)
    }
  }

  // console.debug("component kitni bar chalega")


  function unreadParticularNotifications(notificationId, projectId) {
    console.debug("unreadParticularNotifications", projectId)
    // if (notificationId == "all") {
    //   changeReadNotification(user.uid, notificationId)
    // }
    // else {
      // if(projectId != ""){ 
      let nId = [];
      nId.push(notificationId)
      nId.join(',');
      changeReadNotification(user.uid, nId, projectId)
    // }

  }
  function changeReadNotification(userId, notificationId, projectId) {
    console.debug("changeReadNotification:projectId", projectId)
    console.debug("changeReadNotification:notificationId", notificationId)

    // if(url)
    readNotification(userId, notificationId).then(response => {
      // console.debug("unreadParticularNotifications", response)
      if(response.data.message){ 
        
        console.debug("changeReadNotification:data", response)
      if(projectId != ""){ 
        setReload(true)
      history.push("/app/reports/"+ projectId)
      // window.location.href="/app/reports/"+ projectId
      }else{
        setReload(true)
      console.debug("Modal open karo")
      }
    }

      // setTimeout(function(){
      //   setReload(true)
      // }, 2000)
    
      // setFlag(true)
    })
  }

  function viewAllNotification() {
    // unreadParticularNotifications("all")
    setPage(page + 1)
    // setFlag(true)
  }


  // const handleSearch = debounce(async (searchText: string) => {
  //   if (fetching) return;

  //   setFetching(true);
  //   try {
  //     const searchBlockedDomains = callableFunctions.httpsCallable('searchBlockedDomains');
  //     const result = await searchBlockedDomains({ searchTerm: searchText, pageSize: 10, pageToken: '' });
  //     const { results, nextPageToken, hasMore: moreData } = result.data;

  //     setFilteredBlockedDomain(results);
  //     setPageToken(nextPageToken);
  //     // setHasMore(moreData);
  //   } catch (error) {
  //     console.error('Error searching blocked domains:', error);
  //   }
  //   setFetching(false);
  // }, 500);


  // const searchOnChangeHanlder =(e)=>{
  //   console.debug("searchOnChangeHanlder", e.target.value)
  //   setSearchQuery(e.target.value)
  //   if(e.target.value == ""){ 
  //     console.debug("searchOnChangeHanlder: if :loadBlockedDomains chalega", e.target.value)
  //     setFlag(true);
  //     setFetching(false)
  //   }else{
  //     console.debug("searchOnChangeHanlder: else :handleSearch chalega", e.target.value)
  //     handleSearch(e.target.value);
  //   }
  // }

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Link to="/">
          <img src={sectionLabel.image} style={{ height: "48px" }} />
          {/* <LogoIcon height="48px" /> */}
        </Link>{" "}
        <div className={classes.grow} />
        <SearchBar
          className={classes.search}
          onChange={(e) => {
            // searchOnChangeHanlder(e)
            props.setHeaderSearch(e)
            // setSearchQuery(q);
            // dispatchSearch(searchString(q));
          }}
          onCancelSearch={() => {
            setSearchQuery("");
            dispatchSearch(searchString(""));
          }}
          value={searchQuery}
        />

        <IconButton
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={numUnread > 0 ? numUnread : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          aria-haspopup="true"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <Avatar alt={user.displayName!} src={user.photoURL!} />
        </IconButton>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(undefined)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="ps-3 text-bold">Notifications</h5>
            </div>
            <div className="pe-3" >
              <img src={icon}  className=""/>
            </div>
          </div>
          {numUnread === 0 ? (
            <MenuItem>
              <Typography className={classes.headerEmptyMenu}>
                No new notifications
              </Typography>
            </MenuItem>
          ) : (
            notificationData.length != 0 ? notificationData.map((n) => (
              <MenuItem
                key={n.id}
                onClick={() => setNotificationsMenu(undefined)}
                // className={classes.headerMenuItem}
                className=""

              >
                <Typography component={Link} to={""}
                // onClick={unreadParticularNotifications(n.id)}
                >
                  <div onClick={() => unreadParticularNotifications(n.id, n.projectId)}>
                    <h6 className={n.read == true ? "unReadNotificationTitle text-black-50 mb-0" : " unReadNotificationTitle mb-0"} style={{width:"200px"}}> {n.title}</h6>
                    <p className={n.read == true ?  "unReadNotificationTitle text-black-50 fs-13"  :"unReadNotificationTitle fs-13"} style={{width:"200px"}}>{n.body}</p>
                  </div>
                </Typography>
              </MenuItem>
            )) : []
          )}
          <div className="text-center">
          {hideShowMore == false || notificationData.length != 10 ? <Button variant="contained" className="text-capitalize" onClick={viewAllNotification}> view more</Button> : ""}
          </div>
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(undefined)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>

            <Avatar alt={user.displayName!} src={user.photoURL!} className={classes.large} />
            <Typography variant="body2" weight="bold" >
              {user.displayName ?? "Username"}
            </Typography>
            {/* {process.env.NODE_ENV === "development" && (
              <>
                <Typography>{user.uid}</Typography>
                <Typography>
                  Stripe role: {profile.token?.claims.stripeRole}
                </Typography>
              </>
            )} */}
            {/* <Typography variant="caption">
              {user.email ?? "Anonymous account"}
            </Typography> */}
          </div>
          {/* <MenuItem
            onClick={() => {
              if (hasPlan) dispatchUser(redirectToCustomerPortal());
              else window.location.assign("https://i3d.ai/payment");
            }}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            {payment.paymentLoading ? (
              <CircularProgress
                size="1rem"
                className={classes.profileMenuIcon}
              />
            ) : (
              <AccountIcon className={classes.profileMenuIcon} />
            )}{" "}
            {hasPlan ? "Customer Portal" : "Choose your plan!"}
          </MenuItem> */}
          {<div className={classes.profileMenuUser}>
            <Button variant="contained" color="primary"
              className={classes.profileMenuLink}
              disableElevation>
              {<Typography variant="caption">
                Manage Profile
              </Typography>}
            </Button>
          </div>}
          <Divider light />
          <div className={classes.profileMenuUser}>
            <Button variant="outlined" color="primary"
              className={classes.profileMenuLink}
              startIcon={<ExitToAppIcon />}
              onClick={() => dispatchUser(signOutUser())} disableElevation>
              {<Typography variant="caption">
                Sign Out
              </Typography>}
            </Button>
          </div>
        </Menu>
        <div>
          <ShoppingCartIcon style={{ color: "#858C94" }} />
        </div>
      </Toolbar>
    </AppBar>
  );
}
export default Header;