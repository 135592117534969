import React, {useEffect,useState} from "react";

import {
	Fab,
	Input,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";

import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { getSectorLabel, getStageLabel, overallScore } from "../../pages/create/components/data";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { Typography } from "../../components/Wrappers/Wrappers";
import { ChevronRight } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
// import { fetchAllProjects } from "../../actions/project";
import { addFavourite,removeFavourite } from "../../actions/project";
import FavoriteIcon from '@material-ui/icons/Favorite';
import { RootState } from "../../reducers";
import { Project } from "../../model";
import {ProjectRepo} from "../../repositories/project";
import {  SortingType,ScoreType } from "../../utils/enums";


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);



function ProjectList(props: { project: Project,filter: string, pageType: string }) {

	const { project ,filter, pageType} = props;
	const classes = useStyles();
	const dispatchAddFavourite  =   useDispatch();
	const dispatchRemoveFavourite  =   useDispatch();
	const user = useSelector((state: RootState) => state.firebase.auth);

	const addToFav = (e,projectId) =>{
		e.preventDefault();
		dispatchAddFavourite(addFavourite(projectId));
	}


	function getScoreText(project)
  {
		switch (filter) {
			case ScoreType.expert:

				return (
					<TableCell>
						{(
							project.sectionStats.overall?project.sectionStats.overall.avgRating *
								100:0
						).toFixed(1)}
						%
					</TableCell>
				);
			case ScoreType.technical:
				return (
					<TableCell>
						{((project.technical ? project.technical.overall : 0) * 100).toFixed(1)}
						%
					</TableCell>

				);
			// case SortingType.overallRanking:
			// return (
			// 	<TableCell>
			//         {overallScore(project).toFixed(1)}
			// 	</TableCell>
			// );
			// 	// case SortingType.rankingReversed:
			// 	// 	break;
			default:
				return (
					<TableCell>
						{overallScore(project).toFixed(1)}
					</TableCell>
				);
				break;
		}

	}

	// const removeToFav = (e,projectId) =>{
	const removeToFav = (e) =>{
		e.preventDefault();
		dispatchRemoveFavourite(removeFavourite(project.id));
	}

	return (
		<StyledTableRow	component={Link} to={pageType == 'draft'? `/app/create/${project.id}`: `/app/reports/${project.id}`}>
			<TableCell>
				{
					project.likes===true?(
						<FavoriteIcon onClick={(e)=>{removeToFav(e)}}
							fontSize="small" style={{color:"#FF0000"}} />
					):(
						<FavoriteBorderOutlinedIcon onClick={(e)=>{addToFav(e,project.id)}}
							fontSize="small" style={{color:"#FF0000"}} />
					)
				}
				<div className={classes.likes}>

					<span
						style={{
							fontWeight: "bold",
							fontSize: "12px",
							marginRight: 5
						}}
					>
						{project.likesCount}
					</span>

					<span style={{ fontSize: "10px" }}>
						Likes
					</span>
				</div>
			</TableCell>
			<TableCell>
				<img
					src={project.company.logo}
					alt="company logo"
					className={classes.companyLogo}
				/>
			</TableCell>
			<TableCell>
				<Typography weight="bold" variant="body1">
					{project.company.name}
				</Typography>
			</TableCell>
			<TableCell>
				{getSectorLabel(project.company.sector)}
			</TableCell>
			<TableCell>
				{getStageLabel(project.company.stage)}
			</TableCell>
			<TableCell>
				{project.company.description}
			</TableCell>
			<TableCell>
				{project.sectionStats.overall?project.sectionStats.overall.invited:0}
			</TableCell>
			<TableCell>
				{project.sectionStats.overall?project.sectionStats.overall.numComments:0}
			</TableCell>
			{
				getScoreText(project)
			}
			{/* <TableCell>
				{(
					project.sectionStats.overall?project.sectionStats.overall.avgRating *
					100:0
				).toFixed(1)}
				%
			</TableCell> */}
			{/* <TableCell>
				<ChevronRight />
			</TableCell> */}
		</StyledTableRow>
		

	);
}

export { ProjectList };
