import React, { Dispatch, useEffect, useState } from "react";
import { Formik, FormikProps, useFormikContext } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button as MButton,
  Stepper,
  MobileStepper,
  Step,
  StepLabel,
  StepButton,
  Fab,
  Paper,
  Grid,
  Box,
  Snackbar,
} from "@material-ui/core";
import { useCounter } from "ahooks";
import { Close as CloseIcon, Info } from "@material-ui/icons";
import useStyles from "./styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CompanyInfo, FileUpload, Share, ElevatorPitch, ProblemStatement, Solution, Market, Milestone, Funds, GoToMarket, Tokenomics, Competition, Business } from "./components";
import { Button } from "../../components/Wrappers/Wrappers";
import FadeIn from "react-fade-in";
import { ProjectDispatch, FormInfo, LayoutAction, AddTeams, PDFComponentData } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { projectCreate } from "../../actions/project";
import { RootState } from "../../reducers";
import ReactDOMServer from 'react-dom/server'
import html2pdf from 'html2pdf.js';
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import { countries, sectors } from "./components/data";
import { showUpgradeModal } from "../../actions/layout";
// import { Moment } from "moment";
import AutomateCompanyScore from "./components/AutomateScore/AutomateCompanyScore";
import BusinessLandscape from "./components/BusinessLandscape/BusinessLandscape";
import ExpertScore from "./components/ExpertScore/ExpertScore";
import invitation from '../../repositories/invitation';
import { validationSchema, scoringAssist, projectInitialValues, deepEqual, areAllKeysFilled } from "./components/infos";
// import DemoComponent from "./components/companyDetailPDF";
import CompanyDetailPDF from "./components/companyDetailPDF";
import { callableFunctions, firestore, storage } from "../../repositories/firebase";
import PdfCardComponents from "./components/PdfCardComponents";
import TeamDetailPDF from "./components/teamDetailPDF";
import axios from 'axios';
import PDFComponent from "./components/PDFComponent";
import CreatePitchDeckDialog from "../../components/Modals/CreatePitchDeckDialog";
import ElivaterPitchPdfComponents from "./components/ElivaterPitchPdfComponents";
import SubscriptionCard from "./components/plan";
import { getParentCollection } from "./draftData";
import { Alert } from "@mui/material";
import { companyPitchData, elevatorPitchData, pdfData } from "./components/pitchDeckPdf";
import moment from "moment";

const projectCollection = firestore.collection("project");


function Create() {

  const intitialValues: FormInfo = {
    ...scoringAssist,
   ...projectInitialValues
  
  };
  const classes = useStyles();
  let { id } = useParams();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const history = useHistory();
  const [step, { inc, dec }] = useCounter(0);
  const [yesNoSwitch, setyesNoSwitch] = useState("No")
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const { creating, createError } = useSelector(
    (state: RootState) => state.project
  );
  const userData = useSelector((state: RootState) => state.firebase.auth);
  const dispatch = useDispatch<ProjectDispatch>();
  const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
  const [user, setUser] = React.useState<AddTeams[]>([]);
  const [technicalScoringFund, setTechnicalScoringFund] = React.useState<Record<string, any>[]>([]);
  const [technicalScoringMarket, setTechnicalScoringMarket] = React.useState<Record<string, any>[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeName, setActiveName] = React.useState("");
  const [companyData, setCompanyData] = React.useState("");
  const [pitchDeckUrl, setPitchDeckUrl] = React.useState("")
  const [pdfLoader, setPdfLoader] = React.useState(false)
  const [draftLoader, setDraftLoader] = React.useState(false)

  const [openPitchDeckPopup, setOpenPitchDeckPopup] = useState(false)
  const [companyStepFilled, setCompanyFilled] = React.useState(false);
  const [formikData, setFormikData] = useState<any| undefined>(undefined)
  const [solutionData, setSolutionData] = useState<PDFComponentData | undefined>(undefined);
  const [marketData, setMarketData] = useState<PDFComponentData | undefined>(undefined);
  const [businessData, setBusinessData] = useState<PDFComponentData | undefined>(undefined);
  const [gotoMarketData, setGotoMarketData] = useState<PDFComponentData | undefined>(undefined);
  const [milestoneData, setMilestoneData] = useState<PDFComponentData | undefined>(undefined);
  const [fundsData, setFundsData] = useState<PDFComponentData | undefined>(undefined);
  const [competative, setCompetative] = useState<PDFComponentData | undefined>(undefined);
  const [tokenamicsData, setTokenamicsData] = useState<PDFComponentData | undefined>(undefined);
  const [openPlan, setOpenPlan] = useState(false)
  const [companyPdfData, setCompanyPdfData] = React.useState({});
  const [elivaterPitch, setElivaterPitch] = useState<{ heading: string, cardData: string } | undefined>(undefined);
  const [state, setState] = React.useState(false);
  const [hasNavigatedBack, setHasNavigatedBack] = useState(false);
  const [problemData, setProblemData] = useState<PDFComponentData | undefined>(undefined);
  let clickTypeSubmit = false
  const today = new Date()


  const handleCloseSnackbar = () => {
    setState(false);
  };

  useEffect(() => {
    if (areAllKeysFilled(companyData) == true) {
      setCompanyFilled(true)
    }
  }, [companyData])

  useEffect(() => {
    // for populate data in create form if the project is in autosave
    if(id != '' && id != undefined){ 
    getParentCollection(intitialValues, projectCollection, id, technicalScoringMarket, technicalScoringFund)
    setyesNoSwitch(formikData ? formikData.needExpertScore : "No")
    getTeamDataCallableFunction(id)
    }else{
      console.debug("wjkbjkwk:else mein gaya", intitialValues)
    }
  }, [projectCollection, id != "", technicalScoringMarket.length > 0])

  const openPriceDialog = () => {
    setOpenPlan(true)
  }

  const getTeamDataCallableFunction = async (id) => {
    const getProjectTeamMemberDetail = callableFunctions.httpsCallable('getProjectTeamMemberDetail')
    const result = await getProjectTeamMemberDetail({ projectId: id })
    setUser(result.data.teamData)
  }
  
  var stage = 9
  const location = useLocation()

  useEffect(() => {
    // Validation for plan purchase and project upload
    if (profile.isEmpty == false && profile.isLoaded == true) {
      if (profile.currentPlan) {
        let plan = profile.currentPlan
        if (plan.uploadProject != plan.uploadedProject && plan.uploadProject >= plan.uploadedProject) {
          // return history.push("/create");
        } else {
          if (location.pathname == "/create") {
            return history.push("/app/dashboard");
          }
          return dispatchLayout(showUpgradeModal());
        }
      } else {
        openPriceDialog()
      }
    }
  }, [profile.isEmpty == false && profile.isLoaded == true]);

  useEffect(() => {
    invitation.getTechnicalQuestions().then((res) => {
      setTechnicalScoringFund(res.filter((val) => val.section == 'finance'));
      setTechnicalScoringMarket(res.filter((val) => val.section == 'market'));
    });
    // invitation.getTechnicalMarket().then((res)=>{
    //   setTechnicalScoringMarket(res);
    // });
  }, []);

  useEffect(() => {
    // useEffect is used for formatting data to Populate data in Pitch deck Pdf
    setCompanyPdfData(companyPitchData(formikData ? formikData.companyImage : "",formikData ? formikData.companyDescription : "",formikData ? formikData.companyName : "",formikData ? formikData.companyStage : "",formikData ? formikData.companySector : "",formikData ? formikData.companyWebsite : "",))
    /** Problem Defineition Data */
    setProblemData(pdfData("Problem Definition", "Problem Statement", "Define The Problem", "Market Dynamics", "Pain Points", formikData ? formikData.statement : "", formikData ? formikData.DTP : "", formikData ? formikData.marketDynamics : "", formikData ? formikData.painPoints : "", formikData ? formikData.imageLinks ? formikData.imageLinks.problem1 : "" : "",formikData ? formikData.imageLinks ? formikData.imageLinks.problem2 : "" : "",))
    /** Solution  Data */
    setSolutionData(pdfData("Solution Proposed", "Solution Statement", "UPS's &  Benefits", "Product Key Features", "Use Cases", formikData ? formikData.solutionStatement : "", formikData ? formikData.USPB : "", formikData ? formikData.PKF : "",formikData ? formikData.useCases : "",formikData ? formikData.imageLinks ? formikData.imageLinks.solution1 : "" : "",formikData ? formikData.imageLinks ? formikData.imageLinks.solution2 : "" : ""))
    /** Marget  Data */
    setMarketData(pdfData("Market Potential", "Market Size: TAM, SAM, Target Market", "Market Growth Potential", "Market Opportunity", "Market Trends",formikData ? formikData.marketSizeTam : "",formikData ? formikData.MGP : "",formikData ? formikData.marketOpportunity : "",formikData ? formikData.marketTrends : "",formikData ? formikData.imageLinks ? formikData.imageLinks.market1 : "" : "",formikData ? formikData.imageLinks ? formikData.imageLinks.market2 : "" : "",))
    /** Bussiness  Data */
    setBusinessData(pdfData("Business Model", "Revenue Streams", "Cost Structure and Runway", "Sales Channels-Customer pipeline", "Customer Acquisition Strategy",formikData ? formikData.revenueStreams : "",formikData ? formikData.costStructure : "",formikData ? formikData.salesChannelsCustomer : "",formikData ? formikData.CAS : "",formikData ? formikData.imageLinks ? formikData.imageLinks.business1 : "" : "",formikData ? formikData.imageLinks ? formikData.imageLinks.business2 : "" : "",))
    /** Go To Markeet  Data */
    setGotoMarketData(pdfData("Go To Market", "Target Market Audience",  "User Profiles", "Market Strategy", "Sales Strategy",formikData ? formikData.targetAudience : "",formikData ? formikData.userProfiles : "",formikData ? formikData.marketingStrategy : "",formikData ? formikData.salesStrategy : "",formikData ? formikData.imageLinks ? formikData.imageLinks.goto1 : "" : "",formikData ? formikData.imageLinks ? formikData.imageLinks.goto2 : "" : "",))
    /**Milestone Data */
    setMilestoneData(pdfData("Traction And Milestones", "Milestones &  Traction",  "Market Validation", "Strategic Partnerships", "Future Plans",formikData ? formikData.MT : "",formikData ? formikData.marketValidation : "",formikData ? formikData.strategicPartnerships : "",formikData ? formikData.futurePlans : "",formikData ? formikData.imageLinks ? formikData.imageLinks.milestone1 : "" : "",formikData ? formikData.imageLinks ? formikData.imageLinks.milestone2 : "" : "",))
    /**Funds Data */
    setFundsData(pdfData("Funding and Ask", "Equity and/or Token Structure",  "Use of Funds & Milestones", "Valuation", "Financial Projections Summary",formikData ? formikData.fundingAmount : "",formikData ? formikData.useofFunds : "",formikData ? formikData.valuation : "",formikData ? formikData.financialProjections : "",formikData ? formikData.imageLinks ? formikData.imageLinks.fund1 : "" : "", formikData ? formikData.imageLinks ? formikData.imageLinks.fund2 : "" : "",))
    /**Competative Data */
    setCompetative(pdfData("Competitive Landscape", "Overview about Competitive Landscape", "Identify Competitors", "Competitive Advantage", "MOAT and IP",formikData ? formikData.competitiveLandscape : "",formikData ? formikData.identifyCompetitors : "",formikData ? formikData.competitiveAdvantage : "",formikData ? formikData.MOAT : "",formikData ? formikData.imageLinks ? formikData.imageLinks.competition1 : "" : "",formikData ? formikData.imageLinks ? formikData.imageLinks.competition2 : "" : "",))
    /**Tokenomics Data */
    setTokenamicsData(pdfData("Tokenomics", "Token Utility", "Token Distribution & Vesting periods", "Token Sale Details", "Incentive &  Regulatory Compliance",formikData ? formikData.tokenUtility : "",formikData ? formikData.tokenDistribution : "",formikData ? formikData.tokenSale : "",formikData ? formikData.incentives : "",formikData ? formikData.imageLinks ? formikData.imageLinks.token1 : "" : "", formikData ? formikData.imageLinks ? formikData.imageLinks.token2 : "" : "",))
    /**Elivater Pitch */
    setElivaterPitch(elevatorPitchData("Elevator Pitch", formikData ? formikData.rapidDescription : ""))
  }, [formikData]);

  const handleNext = (step, stage) => {
    setActiveStep(step + 1);
    setActiveName(stage)
  };
  const handleBack = (step, stage) => {
    setActiveStep(step - 1);
    setActiveName(stage)

  };

  const PdfJSX = ({ formikData }) => { // Component of Pitch Deck HTML which shows in the pdf 
    return (
      <div id="element-to-print" >
        <CompanyDetailPDF data={companyPdfData} />
        {formikData.rapidDescription && <>
          < div className="html2pdf__page-break" > </div>
          <ElivaterPitchPdfComponents data={elivaterPitch} /></>}
        {formikData.statement && <>
          < div className="html2pdf__page-break" > </div>
          <PdfCardComponents data={problemData} /></>}
        {formikData.solutionStatement && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={solutionData} />
        </>}
        {formikData.marketSizeTam && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={marketData} />
        </>}
        {formikData.revenueStreams && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={businessData} />
        </>}
        {formikData.targetAudience && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={gotoMarketData} />
        </>}
        {formikData.MT && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={milestoneData} />
        </>}
        {formikData.fundingAmount && <>
          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={fundsData} />
        </>}
        {formikData.competitiveLandscape && <>

          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={competative} />
        </>}
        {formikData.tokenUtility && <>

          < div className="html2pdf__page-break" > </div>
          < PdfCardComponents data={tokenamicsData} />
        </>}
        {user.length && <>
          < div className="html2pdf__page-break" > </div>
          < TeamDetailPDF data={user} />
        </>}
      </div>
    );
  };

  console.debug("shjhsks", id)

  async function goToCreatePdf(values) {  //function to create pitch pdf function at the time of project creation 
 
    setPdfLoader(true)
    const opt = {
      margin: [0, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    const printElement = ReactDOMServer.renderToString(<PdfJSX formikData={formikData} />);
    const modifiedPrintElement = `<div style="background-color: #002060";>
                             ${printElement}
                          </div>`;
    const pdfBlob = await html2pdf().from(modifiedPrintElement).set(opt).outputPdf("blob");

    // Upload the PDF blob to Firestore
    const storageRef = storage.ref('pitchDeck');
    const pdfRef = storageRef.child(`${companyPdfData.companyName}.pdf`);
    try {
      await pdfRef.put(pdfBlob);
      const pdfDownloadUrl = await pdfRef.getDownloadURL();
      

      setPitchDeckUrl(pdfDownloadUrl)
      clickTypeSubmit = true

      const endDate= new Date(profile.currentPlan.planExpireDate)
      if(profile.currentPlan.name == "Start-up"){
       const date=[moment(today).toISOString(), moment(endDate).toISOString()]
       values.dateRange = date
      }
      
    dispatch(projectCreate(values, history, technicalScoringFund, technicalScoringMarket, userData.uid, pdfDownloadUrl, user, clickTypeSubmit, id))
    
    // setPdfLoader(false)
      // console.debug('PDF stored in Firestore:', pdfDownloadUrl);
    } catch (error) {
      console.error('Error storing PDF in Firestore:', error);
    }
  }

 




 
  function getButtons(step, activeName, formik) {
    switch (activeName) {
      case "Company Info":
        return <Button type="button" onClick={() => handleNext(activeStep, "Elevator Pitch")}>
          Next
        </Button>;
      case "Elevator Pitch":
        return (
          <>
            {profile.currentPlan && profile.currentPlan.name === "Start-up" ? 
            <Button type="button" onClick={() => handleNext(10, "Teams")}>
              Next
            </Button> :
              <Button type="button" onClick={() => handleNext(activeStep, "Problem Statement")}>
                Next
              </Button>}

            <Button type="button" onClick={() => handleBack(activeStep, "Company Info")}>
              Back
            </Button></>
        );;
      case "Problem Statement":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Solution")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Elevator Pitch")}>
              Back
            </Button></>
        );;
      case "Solution":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Business Model")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Problem Statement")}>
              Back
            </Button></>
        );
      case "Business Model":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Market")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Solution")}>
              Back
            </Button></>
        );
      case "Market":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Competition")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Business Model")}>
              Back
            </Button></>
        );
      case "Competition":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Milestone")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Market")}>
              Back
            </Button></>
        );
      case "Milestone":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Funds")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Competition")}>
              Back
            </Button></>
        );
      case "Funds":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "GoToMarket")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Milestone")}>
              Back
            </Button></>
        );
      case "GoToMarket":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, formik.values.crypto == true ? "Tokenomics" : "Teams")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "Funds")}>
              Back
            </Button></>
        );
      case "Tokenomics":
        return (
          <> <Button type="button" onClick={() => handleNext(activeStep, "Teams")}>
            Next
          </Button>
            <Button type="button" onClick={() => handleBack(activeStep, "GoToMarket")}>
              Back
            </Button></>
        );
      case "Teams":
        return (
          <>
            <Button type="button" onClick={() => { handleNext(activeStep, "Business Lanscape"); }}>
              Next
            </Button>
            <Button type="button" onClick={() => handleBack(activeStep,  "Tokenomics")}>
              Back
            </Button>
          </>
        );
      case "Business Lanscape":
        // if ((formik.values.scoringTypes).includes("i3D Expert") || (formik.values.scoringTypes).includes("all")) {
          return (
            <>
              {profile.currentPlan.name === "Start-up" ? <Button
                type="button"
                onClick={() => {
                  formik.values.team = user;
                  formik.values.legal = true;
                  goToCreatePdf(formik.values)
                }}
              >
                Submit
              </Button> :
                <Button type="button" onClick={() => { handleNext(12, "Sections"); }}>
                  Next
                </Button>
                 } 

              <Button type="button" onClick={() => handleBack(activeStep, "Teams")}>
                Back
              </Button>
              </>
          );
      case "Sections":
        let validSections =
          !!formik.values.sections.length &&
          formik.values.dateRange.length === 2 &&
          ((formik.values.singlePdf && !!formik.values.singlePdfLink) ||
            Object.keys(formik.values.pdfLinks).length >= 1) &&
          Object.values(formik.values.sectionWeights).reduce(
            (p, n) => p + n,
            0
          ) === 100;
        if (formik.values.crypto) {
          return (
            <>
              <Button disabled={!validSections} type="button" onClick={() => handleNext(activeStep, "Share")}>
                Next
              </Button>
              <Button type="button" onClick={() => handleBack(activeStep, "Business Lanscape")}>
                Back
              </Button>

            </>
          );
        }
        return (
          <>
            <Button disabled={!validSections} type="button" onClick={() => handleNext(activeStep, "Share")}>
              Next
            </Button>
            
            {(formik.values.scoringTypes).includes("i3D Expert") || (formik.values.scoringTypes).includes("all") ? <Button type="button" onClick={() => handleBack(activeStep, "Business Lanscape")}>
              Back
            </Button> : <Button type="button" onClick={() => handleBack(activeStep, "Business Lanscape")}>
              Back
            </Button>}

          </>
        );
      case "Share":
        return (
          <>
            <Button
              disabled={!formik.isValid}
              type="button"
              onClick={() => formik.submitForm()}
            >
              Submit
            </Button>

            <Button
              type="button"
              onClick={() => {
                handleBack(activeStep, "Sections")
              }}
            >
              Back
            </Button>
          </>
        );
      default:
        return (
          <>
            <Button type="button" onClick={() => handleNext(activeStep, "Elevator Pitch")}>
              Next
            </Button>
          </>
        );
    }

  }
  const handleStep = (stage, name, formik) => () => {
    getButtons(stage, name, formik)
    setActiveStep(stage);
    setActiveName(name);

  };

  const openPitchPopup = () => {
    setOpenPitchDeckPopup(true)
    // setOpen(false);
  };

  function onCloseProject(formik) {
    setDraftLoader(true)
      clickTypeSubmit = false
      if(!deepEqual(formik.values, intitialValues)){ 
       dispatch(projectCreate(formik.values, history, technicalScoringFund, technicalScoringMarket, userData.uid, pitchDeckUrl,user, clickTypeSubmit, id));
       setDraftLoader(false)
      }else{
        history.push("/app/dashboard")
      }

  }

  
  function getContent(stage, formik) {
    setFormikData(formik.values)

    switch (stage) {
      case 0:
        return <CompanyInfo setCompanyData={setCompanyData} />;
      case 1:
        return <ElevatorPitch />;
      case 2:
        return <ProblemStatement />;
      case 3:
        return <Solution />;
      case 4:
        return <Business />;
      case 5:
        return <Market />;
      case 6:
        return <Competition />;
      case 7:
        return <Milestone />;
      case 8:
        return <Funds />;
      case 9:
        return <GoToMarket />;
      case 10:
        return <> {formik.values.crypto == true ? <Tokenomics /> : <AutomateCompanyScore user={user} setUser={setUser} />} </>;
      case 11:
        return <> {formik.values.crypto == true ? <AutomateCompanyScore user={user} setUser={setUser} /> : <BusinessLandscape technicalScoringFund={technicalScoringFund} technicalScoringMarket={technicalScoringMarket} />}</>;
      case 12:
        return <>{formik.values.crypto == true ? <BusinessLandscape technicalScoringFund={technicalScoringFund} technicalScoringMarket={technicalScoringMarket} /> : <ExpertScore setyesNoSwitch={setyesNoSwitch} />}</>;

      // case 13:
      //   return <>{formik.values.crypto == true ? <ExpertScore yesNoSwitch={yesNoSwitch} setyesNoSwitch={setyesNoSwitch} /> : <FileUpload />}</>;
      // else
      case 13:
        return <>{formik.values.crypto == true ? <FileUpload /> : <Share />}</>;
      case 14:
        return <Share />;
      default:
        return <CompanyInfo />;
    }
  }
  if (creating && clickTypeSubmit) return <Loader text="Creating project..." />;
  if (draftLoader && clickTypeSubmit == false) return <Loader text="Saving to draft..." />
  if (state == true) return <Loader text="Save to draft..." />;

  if (pdfLoader) return <Loader text="Creating project..." />
 

  if (createError) return <ErrorDisplay error={createError} />;


  const handleDownloadSamplePdf = () => {
    const pdfUrl = './Guide to Creating a Project.pdf'; // Replace with the actual URL or file path of your PDF
    const a = document.createElement('a');
    a.href = pdfUrl;
    a.download = 'Guide to Creating a Project.pdf'; // You can specify the name for the downloaded file
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className={classes.root}>
      {/* <button onClick={onClickHandler}> Click Here</button> */}
      <Formik<FormInfo>
        initialValues={intitialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        // enableReinitialze={true}
        onSubmit={(values) => {
          values.team = user;
          // handleSubmit(values)
          goToCreatePdf(values)
          //  dispatch(createProject(values, history, technicalScoringFund, technicalScoringMarket, userData.uid, pitchDeckUrl));
        }}
      >
        {(formik) => (
          <Form className={classes.root}>
            <FadeIn>
              <AppBar elevation={0} position="static" color="transparent">
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() =>  history.goBack()}
                  >
                    <CloseIcon />
                  </IconButton>
            
                  <MButton color="inherit" onClick={() => onCloseProject(formik)}>
                    Save to drafts
                  </MButton>
                </Toolbar>
              </AppBar>

              <Grid container className={classes.pitchCreateBtn} >
                <Grid item xs={7} className={classes.selection}>
                  <Button
                    className={classes.buttonScore}
                    variant="contained"
                    color="primary"
                    onClick={openPitchPopup} endIcon={<Info fontSize="small" color='white' />}>
                    Creating your Pitch Deck
                  </Button>
                  <Button
                    className={classes.buttonScore}
                    style={{ marginLeft: "2rem" }}
                    variant="contained"
                    onClick={handleDownloadSamplePdf}
                    color="primary" endIcon={<Info fontSize="small" color='white' />}> Sample Pitch & Advice
                  </Button>
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className={classes.stepperContainer}>
                  <Stepper
                    nonLinear
                    activeStep={activeStep}
                    className={classes.stepperCom}
                  // style={{ webkit }}
                  // activeStep={step}
                  >
                    <Step>
                      <StepButton className={companyStepFilled ? classes.stepper : '"'} onClick={handleStep(0, "Company Info", formik)}>Company Info</StepButton>
                    </Step>
                    <Step>
                      <StepButton onClick={handleStep(1, "Elevator Pitch", formik)}>Elevator Pitch</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} className={activeStep == 2 ? classes.activeColor : ""} onClick={handleStep(2, "Problem Statement", formik)}>Problem Statement</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(3, "Solution", formik)}>Solution</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(4, "Business Model", formik)}>Business Model</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(5, "Market", formik)}>Market</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(6, "Competition", formik)}>Competition</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(7, "Milestone", formik)}>Milestone</StepButton>
                    </Step>
                    <Step>
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(8, "Funds", formik)}>Funds</StepButton>
                    </Step>
                    <Step >
                      <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(9, "GoToMarket", formik)}>GoToMarket</StepButton>
                    </Step>
                    {
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton disabled={profile.currentPlan && profile.currentPlan.name === "Start-up" ? true : false} onClick={handleStep(10, "Tokenomics", formik)}>Tokenomics</StepButton>
                        </Step> :
                        <Step>
                          <StepButton onClick={handleStep(10, "Teams", formik)}>Teams</StepButton>
                        </Step>
                    }
                    {
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton onClick={handleStep(11, "Teams", formik)}>Teams</StepButton>
                        </Step> :
                        <Step>0
                          <StepButton onClick={handleStep(11, "Business Lanscape", formik)}>Business Lanscape </StepButton>
                        </Step>
                    }
                    {
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton onClick={handleStep(12, "Business Lanscape", formik)}>Business Lanscape</StepButton>
                        </Step> :
                        (!(formik.values.scoringTypes).includes("i3D Expert") || (formik.values.scoringTypes).includes("all"))
                          ? <Step>
                            <StepButton onClick={handleStep(12, "Expert Score", formik)}>Expert Score</StepButton>
                          </Step> : <></>
                    }

                    {/* When the current plan is pro or Global then Expert Score is not shown and only sections and share are shown */}
                    {(profile.currentPlan && profile.currentPlan.name === "Pro" || profile.currentPlan && profile.currentPlan.name === "Global") && (
                      formik.values.crypto == true ?
                        <Step>
                          <StepButton onClick={handleStep(13, "Sections", formik)}>Sections</StepButton>
                        </Step> : <Step>
                          <StepButton onClick={handleStep(13, "Share", formik)}>Share</StepButton>
                        </Step>)}
                    {(profile.currentPlan && profile.currentPlan.name === "Pro" || profile.currentPlan && profile.currentPlan.name === "Global") && (
                      formik.values.crypto === true ? (
                        <Step>
                          <StepButton onClick={handleStep(14, "Share", formik)}>Share</StepButton>
                        </Step>
                      ) : null
                    )}
                  </Stepper>
                  {getContent(activeStep, formik)}
                  <div style={{ marginBottom: 40 }} />
                  {getButtons(activeStep, activeName, formik)}
                </div>
              </div>
            </FadeIn>
          </Form>
        )}
      </Formik>
      <Box sx={{ width: 500 }}>
        <Snackbar
          autoHideDuration={3000}
          open={state}
          onClose={handleCloseSnackbar}
          message="Your unsaved project will be saved as draft"
        >
          <Alert severity="success" className="snackbarBox">Your unsaved project will be saved as draft!</Alert>
        </Snackbar>
      </Box>
            {/* <SubscriptionCard open={openPlan} setOpenPlan={setOpenPlan} /> */}
      <CreatePitchDeckDialog open={openPitchDeckPopup} setOpenPitchDeckPopup={setOpenPitchDeckPopup} />
    </div>
  );
}

export default Create;
