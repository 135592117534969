import React, { useEffect, Dispatch, useState } from "react";
import {
	withStyles,
	Theme,
	createStyles,
} from "@material-ui/core/styles";

import {
	Fab,
	Input,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Add as AddIcon, ChevronRight } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import SearchBar from "material-ui-search-bar";
import { searchString } from "../../actions/search";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { ProjectRepo } from "../../repositories/project";
import useStyles from "./styles";
import { ProjectCard } from "../../components/ProjectCard";
import { ProjectList } from "../../components/ProjectList";
import { RootState } from "../../reducers";
import { fetchAllProjects } from "../../actions/project";
import {
	ProjectDispatch,
	LayoutAction,
	Project,
	SearchAction,
} from "../../model";
import { showPastDueModal, showUpgradeModal } from "../../actions/layout";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Link } from "react-router-dom";
import ListViewToggle from "../../components/ListToggle/ListToggle";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import FadeIn from "react-fade-in";
import _ from "lodash";
import { getSectorLabel, getStageLabel } from "../create/components/data";
import { ViewType, SortingType } from "../../utils/enums";
import {
	getDefaultViewType,
	setDefaultViewType,
} from "../../utils/localstorage";
import firebase from "firebase";
import { callableFunctions } from "../../repositories/firebase";


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#F2F7FF",
	},
}))(TableCell);

function getDefaultSorting(search: string): SortingType {
	const sorting = new URLSearchParams(search).get("sort");
	switch (sorting) {
		case "myLikes":
			return SortingType.date;
		case "myShare":
			return SortingType.ranking;
		case "MyUploads":
			return SortingType.sector;
		default:
			return SortingType.default;
	}
}

function _byRanking(a: Project, b: Project): number {
	return a.sectionStats.overall.avgRating - b.sectionStats.overall.avgRating;
}

function MyUploads() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();

	const project = useSelector((state: RootState) => state.project);
	const search = useSelector((state: RootState) => state.search);
	const profile = useSelector((state: RootState) => state.firebase.profile);
	const user = useSelector((state: RootState) => state.firebase.auth);
	const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
	const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
	const dispatch = useDispatch<ProjectDispatch>();
	const [sortingType, setsortingType] = useState(SortingType.default);
	
	const [sortedProjects, setsortedProjects] = useState<Project[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [viewType, setViewType] = useState<ViewType>(getDefaultViewType());
	
	// const ap=()=>{
	// 	var addMessage = firebase.functions().httpsCallable('helloWorld');
	// 		// addMessage({ text: messageText })
	// 		addMessage()
	// 		.then((result) => {
	// 			// Read result of the Cloud Function.
	// 			// var sanitizedMessage = result.data.text;
	// 			console.debug("result ap")
	// 			console.debug(result)
	// 		});
	// }
   
    console.debug("Response: MyUploads",sortingType);
	useEffect(() => {
		//dispatch(fetchAllProjects());
		// console.debug("user.uid:My uploads",user.uid)
             ProjectRepo.fetchAll(user.uid).then((res	)=>{
                const type = sortingType.split("|")[0];
				
                let sorted: Project[];
                sorted = _.sortBy(project.allProjects, [type]);
                setsortedProjects(sorted);
             });
	}, []);
	
	useEffect(() => {
		dispatchSearch(searchString(searchQuery));
	}, [dispatchSearch]);

	useEffect(() => {
		setsortingType(getDefaultSorting(location.search));
	}, [location]);
	useEffect(() => {
		if (!project.allProjects.length) return;
		const type = sortingType.split("|")[0];
		const descending = sortingType.includes("desc");
		let sorted: Project[];
		
		switch (sortingType) {
			case SortingType.ranking:
			case SortingType.rankingReversed:
				sorted = project.allProjects.sort(_byRanking);
				break;
			default:
				sorted = _.sortBy(project.allProjects, [type]);
				break;
		}
		setsortedProjects(descending ? sorted.reverse() : sorted);
	}, [project.allProjects, sortingType, search.searchString]);

	if (project.fetching || !sortedProjects.length)
		return <Loader text="Fetching your projects..." />;

	const userName = user.displayName?.split(" ")[0] ?? "there!";

	return (
		<>
			<Fab
				className={classes.fab}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={goToCreate}
			>
				<AddIcon />
				New Project
			</Fab>
			<>
				<PageTitle
					noMargin
					title={
						new Date().getHours() > 12
							? "Good afternoon, " + userName
							: "Good morning, " + userName
					}
					extra={
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
							}}
						>
							<FormControl variant="filled">
								{/* <InputLabel id="sort">Sort By</InputLabel> */}
								<Select
									id="sort"
									label="Sorting"
									value={sortingType}
									style={{
										height: 40,
										backgroundColor: "#F4F6F9",
									}}
									onChange={(e) =>
										setsortingType(
											e.target.value as SortingType
										)
									}
									input={
										<Input
											classes={{
												input: classes.selectInput,
											}}
										/>
									}
								>
									<MenuItem value={SortingType.default}>
										Alphabetical (A-z)
									</MenuItem>
									<MenuItem
										value={SortingType.defaultReversed}
									>
										Alphabetical (z-A)
									</MenuItem>
									<MenuItem value={SortingType.date}>
										Newest - Oldest
									</MenuItem>
									<MenuItem value={SortingType.dateReversed}>
										Oldest - Newest
									</MenuItem>
									<MenuItem value={SortingType.ranking}>
										Score High - Low
									</MenuItem>
									<MenuItem
										value={SortingType.rankingReversed}
									>
										Score Low - High
									</MenuItem>
									<MenuItem value={SortingType.sector}>
										Sector
									</MenuItem>
								</Select>
							</FormControl>
							<ListViewToggle
								checked={viewType === ViewType.list}
								onChange={(_, checked) => {
									const viewType = checked
										? ViewType.list
										: ViewType.grid;
									setViewType(viewType);
									setDefaultViewType(viewType);
								}}
							/>
						</div>
					}
				/>
				{ <div className={classes.container}>
					{viewType === ViewType.grid ? (
						<FadeIn className={classes.gridContainer}>
						  
							{sortedProjects
								.filter((v) =>
									v.company.name
										.toLowerCase()
										.includes(
											search.searchString.length >= 3
												? search.searchString.toLowerCase()
												: ""
										)
								)
								.map((v, index) => {
									// console.debug("project")
									// console.debug(v,"project")
									return (
										<ProjectCard project={v} key={index} />
									)
								})}
						</FadeIn>
					) : (
						<ProjectList
							projects={sortedProjects.filter((v) =>
								v.company.name
									.toLowerCase()
									.includes(search.searchString.toLowerCase())
							)}
						/>
					)}
				</div> }
			</>
		</>
	);

	function goToCreate(e: React.MouseEvent): void {
		e.preventDefault();
		if ("admin" === profile.role) return history.push("/create");
		switch (profile.planStatus) {
			case "active":
			case "trialing":
				if (
					profile.monthlyProjectsLeft &&
					profile.monthlyProjectsLeft > 0
				)
					return history.push("/create");
				return dispatchLayout(showUpgradeModal());
			case "past_due":
				return dispatchLayout(showPastDueModal());
			default:
				return dispatchLayout(showUpgradeModal());
		}
	}
}



export default MyUploads;
function fetchMyUploads() {
    throw new Error("Function not implemented.");
}

