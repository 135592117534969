import axios from "axios";
import {app} from "../repositories/firebase";


export async function getUserNotification(userId=null, limit=null, page=null, token){
    const instanceurl =app.options.apiUrl
    // console.debug("readNotification", notificationId)
    console.debug("token", token)
    let prarmTempObj={};
    if(userId){
        prarmTempObj.userId=userId;
    }
    if(limit){
        prarmTempObj.limit=limit;
    }
    if(page){
        prarmTempObj.page=page;
    }
   
    

    const response=await axios.get(
        // "https://us-central1-invluencer-test.cloudfunctions.net/userListnotification",
        // "https://us-central1-invluencer-dev
        "https://us-central1-invluencer-v2-a22c3.cloudfunctions.net/userListNotification",

        {
            params:prarmTempObj,
            // headers: {
            //     Authorization: `Bearer ${token.token.token}`  // Add Authorization header
            // }
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}