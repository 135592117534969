import React from "react";
import { Field, Formik, Form } from "formik";
import {
  CircularProgress,
  Typography,
  Button,
  Fade,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
// import google from "./../../images/google.svg";
// import i3dlogo from "./../../images/i3Dlogo.png";
// import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { LoginInfo, loginValidation } from "./index";
import { UserDispatch } from "../../model";
import { loginUserWithGoogle, loginUser } from "../../actions/user";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField } from "material-ui-formik-components";
import { useHistory } from "react-router-dom";

export function LoginForm(props) {
  // global
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<UserDispatch>();
  const classes = useStyles();
  const [passwordShown, setpasswordShown] = React.useState<boolean>(false);
  const history = useHistory();
  const from = props.from ? props.from: "/";
  console.debug("redirect Url", from)

  return (
    <Formik<LoginInfo>
      initialValues={{
        email: "",
        password: "",
      }}
      validateOnMount
      validateOnChange
      validateOnBlur
      validationSchema={loginValidation}
      onSubmit={(values) => dispatch(loginUser(values.email, values.password,from,history))}
    >
      {(formik) => {
        return (
          <Form onSubmit={formik.submitForm}>
            {/* <Typography variant="h1" className={classes.greeting}>
              Welcome Back!
            </Typography> */}
            {/* <div className="text-center">
            <img src={i3dlogo} alt="google" className={classes.i3dLogo} />
            </div> */}
            {/* <Button
              size="large"
              className={classes.googleButton}
              onClick={() => {
                dispatch(loginUserWithGoogle());
              }}
            >
              <img src={google} alt="google" className={classes.googleIcon} />
              &nbsp;Sign in with Google
            </Button> */}
            {/* <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
              <Typography className={classes.formDividerWord}>or</Typography>
              <div className={classes.formDivider} />
            </div> */}
            <Fade in={!!user.error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {user.error ?? "Something went wrong during login."}
              </Typography>
            </Fade>
            <Field className={classes.formikPassword}
              required
              name="email"
              label="Valid Email"
              component={TextField}
              margin="normal"

              placeholder="Valid Email"
              type="email"
              // className="p-3 border border-info border-3 rounded"
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                  // label: classes.textField,
                },
              }}
              
            />
            
            <Field className={classes.formikPassword}
              required
              name="password"
              label="Password"
              component={TextField}
              margin="normal"
              placeholder="Password"
              type={passwordShown ? "text" : "password"}
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                  
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setpasswordShown(!passwordShown)}
                      edge="end"
                    >
                      {passwordShown ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.formButtons}>
              {user.isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button className={classes.loginButton}
                  disabled={!formik.isValid}
                  onClick={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Login
                </Button>
              )}
              <Button
                color="primary"
                size="large"
                type="button"
                className={classes.forgetButton}
                onClick={() => history.push("/forgotPassword")}
              >
                Forgot Password
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
    }
