import React, { useEffect, useState } from "react";
import { ProjectCard } from "../../components/ProjectCard";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Filter } from "../../utils/enums";
import { Box, Button } from "@mui/material";
import useStyles from './styles';

interface ProjectGridProps {
  sortedProjects: any[];
  scoreSort: any;
  fetching: boolean;
  hasMore: boolean;
  handleLoadMore: () => void;
  filter: Filter;
}

export default function ProjectGrid({
  sortedProjects,
  scoreSort,
  fetching,
  hasMore,
  handleLoadMore,
  filter,
}: ProjectGridProps) {
  const classes = useStyles();
  const search = useSelector((state: RootState) => state.search);
  const user = useSelector((state: RootState) => state.firebase.auth);
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const [loadMoreFilter, setLoadMoreFilter] = useState('')

  // console.debug("xasxhasbjkbjkxs:sortedProjects", sortedProjects)

  const filteredProjects = sortedProjects.filter((v) =>
    v.company.name
      .toLowerCase()
      .includes(
        search.searchString.length >= 3
          ? search.searchString.toLowerCase()
          : ""
      )
  );

  useEffect(() => {
    // Update the loadMoreFilter based on scoreSort and filter
    const newFilter = `${scoreSort}-${filter}`;
    setLoadMoreFilter(newFilter);
  }, [scoreSort, filter]);

  console.debug("scajbkscjk", scoreSort)

  return (
    <>
      <Box className={classes.gridContainer}>
        {filteredProjects.map((v, index) => {
          if (profile?.currentPlan) {
            // If user has a plan, show all projects based on the filter
           return <ProjectCard project={v} key={index} filter={filter} scoreSort={scoreSort} />;
          } else {
            // If user has no plan, limit project display based on filters
            return <ProjectCard project={v} key={index} filter={filter} />;
     
          }
          return null;
        })}
      </Box>

      {/* Load More Button */}
      {hasMore && (
        <Button
          onClick={handleLoadMore}
          disabled={fetching}
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
        >
          {fetching ? "Loading..." : "Load More"}
        </Button>
      )}
    </>
  );
}
