import * as Yup from "yup";
import { countries, sectors } from "./data";
import { FormInfo } from "../../../model";
import { Button } from "../../../components/Wrappers/Wrappers";

export const info = {
  elevatorPitch: `An elevator pitch is a concise and compelling summary of your business idea that can be delivered in the time it takes to ride an elevator, typically 30 seconds to 2 minutes. When it comes to fundraising from investors, the core components of a fantastic elevator pitch should include:

    Problem: Start by clearly identifying the problem your business is solving. This should be a real problem that your target customers face and that is not currently being adequately addressed.
    
    Solution: Once you have identified the problem, explain how your business solves it. Be specific and provide a clear explanation of how your product or service is different from existing solutions.
    
    Market: Investors want to know that there is a large, addressable market for your product or service. Explain the size of your target market and why it is a good opportunity.
    
    Traction: Investors want to see evidence that your business has momentum and is gaining traction. Share any relevant metrics, such as customer acquisition, revenue growth, or user engagement, that demonstrate that your business is on the right track.
    
    Team: Investors want to invest in people they believe can execute on the business plan. Highlight the key members of your team, their relevant experience, and their roles in the business.
    
    Ask: Finally, be clear about what you are asking for from the investor. Whether it is a specific amount of funding or an introduction to someone in their network, make it clear what you are looking for.
    
    Remember that the purpose of the elevator pitch is to pique the investor's interest and convince them to want to learn more. Keep it concise, clear, and compelling.`,

  problem: `Clear and concise problem statement: Start by clearly and concisely defining the problem that your business is solving. It should be a real problem that your target customers face and that is not currently being adequately addressed.

    Market dynamics: Explain the market dynamics, including the trends, the current landscape, and the unmet needs of the market. This shows that you have a deep understanding of the market and the problem you are solving.
    
    Customer pain points: Identify and articulate the pain points that your target customers are experiencing. This helps investors understand the urgency of the problem and the potential impact of your solution.`,
  solution: `In fundraising from investors with a pitch deck, the solution to the problem identification section is where you explain how your business solves the problem that you identified. Here are some core components that make for a fantastic solution section:
    
    Explanation of the solution: Clearly and concisely explain how your product or service solves the problem that you identified. Focus on the benefits that your solution provides to the customer.
    
    Unique selling proposition: Explain what makes your solution unique and why it is better than existing solutions. Highlight the key features or characteristics that differentiate your solution from the competition. 
    
    Product demonstration: Use visuals or a product demo to show how your solution works and how it solves the problem. This can help investors understand the product better and see its potential.
    
    Key features: Highlight the key features of your product or service. Explain how they work and how they address the needs of your target market.
    
    Use cases: Provide examples of how your product or service can be used in real-world scenarios. Use case studies or customer testimonials to demonstrate the value of your product.
    
    Product roadmap: Provide a high-level overview of your product roadmap. Explain what features and functionality you plan to add in the future and how you plan to evolve the product over time.
    
    Remember that the purpose of the solution section is to convince investors that your solution is innovative, effective, and scalable. Keep it concise, clear, and compelling. Use visuals, demos, and data to support your claims and make your solution come to life
    `,
  market: `In fundraising from investors with a pitch deck, the market opportunity slide is where you explain the size and potential of the market that your business is targeting. Here are some core components that make for a fantastic market opportunity slide:
    Market size: Provide data on the size of the market, including the total addressable market (TAM), the serviceable available market (SAM), and the SOM target market. Investors want to see that there is a large, addressable market for your product or service.
    Market growth: Explain the growth potential of the market. Use data and projections to show that the market is growing or is expected to grow in the future.
    Market trends: Describe the key trends and drivers in the market. Explain how your business is well-positioned to take advantage of these trends.
    Market segments: Identify the key customer segments within the market and explain how your business is targeting them. Highlight any specific customer needs or pain points that your business is addressing.
    Market opportunity: Summarize the market opportunity in a clear and concise manner. Explain the potential revenue and growth opportunities that your business can achieve in the market.
    Remember that the purpose of the market opportunity slide is to convince investors that there is a significant market opportunity for your business. Use data, projections, and market research to support your claims and demonstrate that you have a deep understanding of the market. Highlight any unique advantages that your business has in the market, such as a first-mover advantage or a differentiated product.
    `,
  goto: `The go-to-market slide is where you explain how you plan to reach and acquire customers. Here are some core components that make for a fantastic go-to-market slide:
    Target market: Clearly define your target market and explain why it is attractive. Use data and market research to support your claims.
    Customer persona: Create a customer persona to help investors understand your target market. This should include details about your ideal customer's demographics, behavior, and pain points.
    Marketing strategy: Explain your marketing strategy and how it will help you reach your target market. This could include content marketing, paid advertising, social media, email marketing, and PR.
    Sales strategy: Explain your sales strategy and how it will help you acquire customers. This could include direct sales, partnerships, or e-commerce.
    Customer acquisition costs: Explain your customer acquisition costs and how they relate to your revenue streams. Highlight any strategies you have to reduce these costs.
    Key metrics: Highlight the key metrics you will use to measure the success of your go-to-market strategy. This could include customer acquisition costs, customer lifetime value, and conversion rates.
    Remember that the purpose of the go-to-market slide is to show investors that you have a clear plan for reaching and acquiring customers. Use data, research, and market analysis to support your claims and demonstrate that you have a scalable and effective go-to-market strategy. Be clear and concise in your explanation of your target market, customer persona, marketing and sales strategies, and customer acquisition costs. Highlight any unique advantages that your go-to-market strategy offers and explain how they set you apart from the competition.
    `,
  fund: `The Funding Ask slide is a crucial component to include. This slide should clearly communicate how much money the company wants to raise and why. Here are some essential components to include in this slide:
    Funding Amount: State the amount of funding the company is seeking. Make sure the number is clear and easy to read.
    Use of Funds: Outline how the company plans to use the funds raised. Be specific and detailed, highlighting the areas of the business that will benefit from the funding.
    Milestones: Outline the key milestones that the company plans to achieve with the funding, such as product launches, team expansion, or market penetration.
    Financial Projections: Provide financial projections that demonstrate how the funding will impact the company's growth and success. Highlight key metrics, such as revenue, customer acquisition, or profitability.
    Valuation: Provide an estimated valuation for the company. This can be based on current financials, market analysis, or comparable companies in the industry.
    Investment Opportunity: Highlight the investment opportunity, including potential returns and any unique features that make the investment attractive. Provide information on any previous investors or funding rounds to demonstrate credibility and potential.
    Funding Structure: Outline the funding structure, including the type of investment being sought (equity, debt, etc.), the terms of the investment, and any relevant details about the funding round.
    Call to Action: Provide a clear call to action, outlining the next steps for interested investors to get involved and invest in the project.
    `,
  business: `The business model slide is where you explain how your company will generate revenue and sustain profitability. Here are some core components that make for a fantastic business model slide:
    Revenue streams: Explain the different revenue streams that your company will have. This could include selling products or services, licensing fees, or subscription fees.
    Pricing strategy: Explain your pricing strategy and how it relates to your revenue streams. Highlight any unique pricing advantages that your product or service offers.
    Cost structure: Explain your cost structure and how it relates to your revenue streams. This could include costs associated with product development, manufacturing, marketing, and distribution.
    Sales and distribution channels: Explain your sales and distribution channels and how they will contribute to your revenue streams. This could include direct sales, e-commerce, or partnerships with other companies.
    Customer acquisition strategy: Explain your customer acquisition strategy and how it will help you generate revenue. This could include marketing campaigns, social media outreach, or referral programs.
    Key metrics: Highlight the key metrics that you will use to measure the success of your business model. This could include customer acquisition costs, customer lifetime value, and profit margins.
    Remember that the purpose of the business model slide is to show investors that you have a clear plan for generating revenue and sustaining profitability. Use data, research, and market analysis to support your claims and demonstrate that you have a scalable and sustainable business model. Be clear and concise in your explanation of your revenue streams, pricing strategy, cost structure, and sales channels. Highlight any unique advantages that your business model offers and explain how they set you apart from the competition.
    `,
  tokenomics: `When creating a pitch deck for a blockchain or crypto project, the Tokenomics Components are crucial elements to include. Here are some essential components to include in the slide:
    Token Utility: Describe the role of the token in the project's ecosystem, how it will be used, and the benefits it will offer to users. Highlight any unique features that differentiate the token from others in the market.
    Token Distribution: This should include information about the total supply of tokens, the percentage of tokens allocated to the team, investors, and community, and any vesting schedules that apply.
    Token Economics: Provide an overview of the token's economic model, including the inflation rate, staking rewards, and any burning mechanisms that will be implemented to control supply.
    Governance: Outline how token holders can participate in governance, vote on proposals, and influence decision-making.
    `,
  milestones: `The Traction & Milestones slide is critical in demonstrating the project's progress and potential for success. Here are some essential components to include in this slide:
    Traction: Provide information on the project's traction to date, such as the number of users, customers, or partnerships. Highlight any key metrics that demonstrate growth or engagement, such as revenue or retention rates.
    Milestones: Outline the project's key milestones, such as product launches, new feature releases, or regulatory approvals. Highlight any significant achievements that demonstrate progress towards achieving the project's goals.
    Market Validation: Provide evidence of market validation, such as customer testimonials, user feedback, or market research. Highlight any awards or recognition the project has received that demonstrate credibility and potential.
    Partnerships: Provide information on any partnerships or collaborations the project has formed, highlighting the value they add to the project and how they contribute to the project's growth and success.
    Future Plans: Provide a roadmap or overview of the project's future plans, outlining upcoming milestones and key initiatives that will drive growth and success. Highlight any new markets, features, or partnerships that the project plans to pursue.
    `,
  competition: `the competition slide is where you identify and analyze your competitors. Here are some core components that make for a fantastic competition slide:
    Competitor overview: Provide an overview of your main competitors. Explain who they are, what they do, and how they compare to your product or service.
    Competitive landscape: Use a visual representation to show where your product fits into the competitive landscape. This could be in the form of a market map, a SWOT analysis, or a chart comparing key features and benefits.
    Competitive advantage: Explain how your product or service is better than the competition. Highlight any unique advantages or benefits that your product offers that are not available in competing products.
    Market share: Explain the market share of your competitors and how you plan to gain a competitive advantage. Use data and projections to show that your product has the potential to gain market share in the future.
    Pricing strategy: Explain your pricing strategy and how it compares to your competitors. Highlight any pricing advantages that your product or service offers.
    Barriers to entry: Explain any barriers to entry that may prevent new competitors from entering the market. This could be related to intellectual property, regulatory requirements, or other factors.
    Remember that the purpose of the competition slide is to show investors that you have a deep understanding of the competitive landscape and how your product or service compares to the competition. Use data, research, and competitive analysis to support your claims and demonstrate that you have a clear strategy for gaining market share. Be honest and transparent about the competition, but also highlight the unique advantages of your product or service that set you apart from the competition.
    `,

    weight:`The weights assigned to each section by an investor specializing in early-stage startups, 
    are critical as they reflect the relative importance of each factor in the investment decision-making process. 
    The weights may vary depending on the specific industry, market trends, and investment strategy,
     but here is a general recommendation based on industry best practices and historical performance:
                       
    Idea: 25% - The strength and uniqueness of the startup's idea and value proposition are crucial for its potential 
    success and scalability.
  
    Team: 25% - The quality and experience of the founding team play a significant role in the execution of the idea. 
    A strong and diverse team with relevant expertise is more likely to navigate challenges effectively.
  
    Market: 20% - Understanding the size, growth potential, and dynamics of the target market is essential.
     A sizable and expanding market presents more significant opportunities for the startup's growth.
  
    Competition: 10% - Assessing the competitive landscape helps gauge the startup's differentiation and 
    potential barriers to entry. A thorough analysis of competitors is necessary for long-term sustainability.
  
    Financial: 15% - Analyzing the financials, revenue model, and projections are essential in understanding the startup's
     potential for profitability and return on investment.
  
    ESG (Environmental, Social, and Governance): 5% - In recent years, ESG considerations have gained importance in 
    investment decisions. Evaluating the startup's commitment to sustainability and social responsibility can impact
     its long-term success and reputation.;`
}

export const headingInfo = {
  problemDefinition: `In analyzing an early- stage start-up, it's crucial to evaluate the problem they are trying to solve.
   The problem statement should clearly define the issue that the company is addressing.
   Understanding the problem is the foundation of building a viable solution. 
   By defining the problem, you can identify the pain points that the customers  are experiencing and the market dynamics  that are driving the demand for a solution. 
   This information is critical for assessing the potential of the start-up and determining whether it can create a sustainable business model.`,
  solutionProposed: `The solution component of an early-stage start-up is where the rubber meets the road.
   A clear solution statement should be the centrepiece of this component, describing how the start-up plans to solve the problem they have identified.
   It's important to highlight the unique selling propositions (USPs) and benefits that set the start-up apart from potential competitors.
   Additionally, product key features should be outlined in detail, showcasing the technology or approach the start-up is using to solve the problem. 
   Providing use cases can help illustrate how the solution can be applied in practice and what the customer experience will look like. 
   Understanding the solution is essential to assessing the potential of the start-up and determining whether it can deliver on its promise. `,
  marketPotential: `The market component of an early stage start-up is critical to understanding its potential for success. 
  The market opportunity should be clearly defined, outlining the problem the start-up is addressing and the size of the market that exists for a solution. 
  Evaluating the total addressable market (TAM), serviceable addressable market (SAM), and target market can help determine the potential size of the market opportunity. 
  Market growth potential is also an essential factor to consider, as it can indicate the potential for future success. 
  Understanding market trends can help determine if the market opportunity is growing or shrinking and if there is a shift in consumer behavior that the start-up can capitalize on. 
  By analyzing the market, you can better understand the potential demand for the start-up's solution and assess its potential for long-term growth. `,
  businessModel: `The business model component of an early-stage start-up is essential to understanding its potential for success.
  Revenue streams are a critical factor to consider, outlining how the start-up plans to generate revenue and the potential for scalability. 
  Evaluating the cost structure and runway can help determine the financial viability of the start-up and its potential for long-term success. 
  Understanding the sales channels and customer pipeline is essential, as it provides insights into how the start-up plans to reach its target customers and generate revenue. 
  A clear customer acquisition strategy is critical, outlining how the start-up plans to acquire and retain customers. 
  Analyzing the business model is essential to determining the potential for success and the viability of the start-up's financial model. `,
  goToMarket: `The go-to-market strategy component of an early-stage start-up is critical to its success.
  Identifying the target market audience is essential, as it helps to determine the potential customer base and the needs of the target market. 
  Understanding user profiles is critical, as it provides insights into the characteristics of the target customer and their buying behavior. 
  A clear marketing strategy is essential, outlining how the start-up plans to reach its target audience and generate interest in its solution. 
  A well-defined sales strategy is also critical, outlining how the start-up plans to convert leads into paying customers. 
  Analyzing the go-to-market strategy is essential to determining the potential for success and the viability of the start-up's ability to generate revenue.`,
  tractionAndMilestones: `The traction and milestones component of an early stage start-up is essential to understanding its progress and potential for success. 
  Milestones and traction provide insights into the company's achievements and progress in achieving its objectives. 
  Market validation is critical, outlining the reception of the start-up's solution in the market and its potential for scalability. 
  Strategic partnerships can be a significant factor in the start-up's success, providing access to key resources and potential customers. 
  Future plans are also critical to consider, outlining the start-up's roadmap and goals for the future. 
  Analyzing traction and milestones is essential to determining the potential for success and the viability of the start-up's business model.`,
  fundingAndAsk: `The funding ask component of an early stage start-up is critical to its ability to grow and achieve its objectives. 
  The funding amount and investment structure provide insights into the start-up's financial needs and how investors can participate in its growth. 
  Understanding the use of funds and milestones is essential, outlining how the start-up plans to allocate the funds and what objectives it aims to achieve with them. 
  Valuation is also critical, as it provides insights into the start-up's potential for future growth and profitability. 
  A summary of financial projections is also essential to consider, providing an overview of the start-up's financial performance and potential for future profitability. 
  Analyzing the funding ask is essential to determining the potential for success and the viability of the start-up's ability to achieve its objectives. `,
  competitiveLandscape: `The competitive landscape of an early-stage start-up is a crucial component in determining its potential for success. 
  An overview of the competitive landscape can provide valuable insights into the competitive dynamics of the market and the challenges that the start-up may face. 
  Identifying competitors is critical, as it helps understand the existing solutions available to customers and how the start-up plans to differentiate itself. 
  Evaluating the start-up's competitive advantage can help determine its potential for success in a crowded market. 
  Understanding the start-up's moat and intellectual property (IP) can provide insights into the barriers to entry and the potential for long-term success. 
  Analyzing the competitive landscape is essential to determining the potential for success and the viability of the start-up's business model.`,
  tokenomics: `The tokenomics component of an early stage start-up is essential to understanding its potential for success in the cryptocurrency market. 
  Token utility is critical, outlining the specific use cases and benefits of the token for its holders. 
  Token distribution and vesting periods provide insights into how the tokens will be allocated to investors and team members and when they will become available. 
  Understanding token sale details is critical, outlining the specifics of the token sale, including the pricing and the amount of tokens available for purchase. 
  Incentives and regulatory compliance are also critical factors to consider, outlining the potential incentives for token holders and the regulatory compliance requirements that the start-up must meet. 
  Analyzing the tokenomics is essential to determining the potential for success and the viability of the start-up's token economy. `,
}



export const fields = {
  problem: [{ value: 'statement', label: 'Problem Statement' }, { value: 'DTP', label: 'Define The Problem' }, { value: 'marketDynamics', label: 'Market Dynamics' }, { value: 'painPoints', label: 'Pain Points' }],
  solution: [{ value: 'solutionStatement', label: 'Solution Statement' }, { value: 'USPB', label: "USP's & Benefits" }, { value: 'PKF', label: 'Product Key Features' }, { value: 'useCases', label: 'Use Cases' }],
  competition: [{ value: 'competitiveLandscape', label: 'Overview about Competitive Landscape' }, { value: 'identifyCompetitors', label: 'Identify Competitors' }, { value: 'competitiveAdvantage', label: 'Competitive Advantage' }, { value: 'MOAT', label: 'MOAT and IP' }],
  market: [{ value: 'marketSizeTam', label: 'Market Size: TAM, SAM, Target Market' }, { value: 'MGP', label: 'Market Growth Potential' }, { value: 'marketOpportunity', label: 'Market Opportunity' }, { value: 'marketTrends', label: 'Market Trends' },],
  business: [{ value: 'revenueStreams', label: 'Revenue Streams' }, { value: 'costStructure', label: 'Cost Structure and Runway' }, { value: 'salesChannelsCustomer', label: 'Sales Channels - Customer pipeline' }, { value: 'CAS', label: 'Customer Acquisition Strategy' },],
  funding: [{ value: 'fundingAmount', label: 'Funding Amount and Investment Structure' }, { value: 'useofFunds', label: 'Use of Funds & Milestones' }, { value: 'valuation', label: 'Valuation' }, { value: 'financialProjections', label: 'Financial Projections Summary' },],
  gotomarket: [{ value: 'targetAudience', label: 'Target Market Audience' }, { value: 'userProfiles', label: 'User Profiles' }, { value: 'marketingStrategy', label: 'Marketing strategy' }, { value: 'salesStrategy', label: 'Sales Strategy' },],
  milestones: [{ value: 'MT', label: 'Milestones & Traction' }, { value: 'marketValidation', label: 'Market Validation' }, { value: 'strategicPartnerships', label: 'Strategic Partnerships' }, { value: 'futurePlans', label: 'Future Plans' },],
  tokenomics: [{ value: 'tokenUtility', label: 'Token Utility' }, { value: 'tokenDistribution', label: 'Token Distribution & Vesting periods' }, { value: 'tokenSale', label: 'Token Sale Details' }, { value: 'incentives', label: 'Incentives & Regulatory Compliance' },]

}

export const deepEqual=(obj1, obj2)=> {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
      return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
      }
  }

  return true;
}

export const areAllKeysFilled=(obj)=> {
  for (let key in obj) {
    if (!obj.hasOwnProperty(key) || !obj[key]) {
      return false;
    }
  }
  return true;
}

export const isEqualWithCoercion = (a, b) => {
  const keysA = Object.keys(a).filter(key => key !== 'dateRange');
  const keysB = Object.keys(b).filter(key => key !== 'dateRange');
  if (keysA.length !== keysB.length) {
    return false;
  }
  for (const key of keysA) {
    const valueA = a[key];
    const valueB = b[key];
    if (typeof valueA === 'object' && typeof valueB === 'object') {
      // Recursively compare objects
      if (!isEqualWithCoercion(valueA, valueB)) {
        return false;
      }
    } else if (Array.isArray(valueA) && Array.isArray(valueB)) {
      // Compare arrays element by element
      if (valueA.length !== valueB.length) {
        return false;
      }
      for (let i = 0; i < valueA.length; i++) {
        if (typeof valueA[i] === 'object' && typeof valueB[i] === 'object') {
          // Recursively compare objects within arrays
          if (!isEqualWithCoercion(valueA[i], valueB[i])) {
            return false;
          }
        } else if (valueA[i] != valueB[i]) {
          return false;
        }
      }
    } else if (valueA != valueB) {
      return false;
    }
  }
  return true;
};

export const scoringAssist = {
  statement: "",
  DTP: "",
  painPoints: "",
  marketDynamics: "",
  solutionStatement: "",
  USPB: "",
  PKF: "",
  useCases: "",
  competitiveLandscape: "",
  identifyCompetitors: "",
  competitiveAdvantage: "",
  MOAT: "",
  marketSizeTam: "",
  MGP: "",
  marketOpportunity: "",
  marketTrends: "",
  revenueStreams: "",
  costStructure: "",
  salesChannelsCustomer: "",
  CAS: "",
  fundingAmount: "",
  useofFunds: "",
  valuation: "",
  financialProjections: "",
  targetAudience: "",
  userProfiles: "",
  marketingStrategy: "",
  salesStrategy: "",
  MT: "",
  marketValidation: "",
  strategicPartnerships: "",
  futurePlans: "",
  tokenUtility: "",
  tokenDistribution: "",
  tokenSale: "",
  incentives: "",
}

export const projectInitialValues={
  legal: true,
  sections: ["team", "idea", "market", "competition", "finance", "esg"],
  emails: [],
  selectedEmails: [],
  companyName: "",
  companyDescription: "",
  rapidDescription: "",
  companyImage: "",
  companySector: "",
  companyLocation: "",
  companyStage: "early",
  companyWebsite: "",
  privacy: 0,
  dealSource: "",
  contactEmail: "",
  sectionWeights: { "competition": 10, "esg": 5, "finance": 15, "idea": 25, "market": 20, "team": 25 },
  emailSections: {},
  sendProjectToMe: false,
  dateRange: [],
  scoringTypes: ["Technical", "i3D Expert", "i3D Rapid", "all"],
  pdfLinks: {},
  imageLinks: {},
  singlePdf: false,
  singlePdfLink: "",
  crypto: true,
  CompetitiveLandscape: 'No Competitive Landscape selected',
  MarketingPartners: "No Marketing Partners selected",
  SocialNetwork: "No Social & Networking Presence selected",
  industryCagr: "No   Industry CAGR selected",
  marketRisk: "Market Risk Perception",
  SalesChannels: "No Sales Channels selected",
  marketSize: "No Size of Market selected",
  MAU: "",
  TRL_Levels: '',
  needExpertScore: 'No',
  fundFromWhere: "Funds come from where?",
  burnRate: "Burn Rate",
  RevenueStage: '',
  CapitalSize: "Size of intended capital raise",
  ExternalFundYear: "ExternalFundYear"

};

export const validationSchema = Yup.object().shape<FormInfo>({
  companyImage: Yup.mixed(),
  companyName: Yup.string()
    .max(30, "Company name must be less than 30 characters")
    .required("The company needs a name!"),
  companyDescription: Yup.string()
    .min(10, "Description must be at least 10 characters")
    .max(2500, "Description must be less than 2500 characters")
    .required("The company needs a description!"),

  rapidDescription: Yup.string()
    .max(2500, "Rapid Description must be less than 2500 characters")
    .required("The company needs a description for the rapid app"),
  // DTP: Yup.string()
  //   .max(250, "Rapid Description must be less than 2500 characters")
  //   .required("The company needs a description for the rapid app"),
  // statement: Yup.string()
  //   .max(250, "Rapid Description must be less than 2500 characters")
  //   .required("The company needs a description for the rapid app"),
  // marketDynamics: Yup.string()
  //   .max(250, "Rapid Description must be less than 2500 characters")
  //   .required("The company needs a description for the rapid app"),
  // painPoints: Yup.string()
  //   .max(250, "Rapid Description must be less than 2500 characters")
  //   .required("The company needs a description for the rapid app"),
  companySector: Yup.string().oneOf(
    sectors.map((s) => s.value),
    "This company needs a sector of operations!"
  ),
  companyLocation: Yup.string().oneOf(
    countries.map((s) => s.name),
    "This company needs a sector of operations!"
  ),
  companyStage: Yup.string().required("This company needs a stage to be at!"),
  companyWebsite: Yup.string()
    .url("This website is not valid.")
    .required("A company must also have a website."),
  dealSource: Yup.string().url().optional(),
  contactEmail: Yup.string().optional(),
  privacy: Yup.number(),
  sections: Yup.array<string>()
    .min(1, "At least 1 section must be selected.")
    .required("This field is required"),
  emails: Yup.array<string>()
    .min(1, "At least one email must be provided.")
    .max(15, "Maximum 15 emails allowed.")
    .required("This field is required."),
  selectedEmails: Yup.array<string>()
    // .min(1, "At least one email must be provided.")
    .max(15, "Maximum 15 emails allowed.")
    .optional(),
  dateRange: Yup.array<Moment>()
    .min(2, "There must be a start AND end date to this project.")
    .max(2, "There must be a start AND end date to this project.")
    .required("This field is required."),
  sectionWeights: Yup.mixed().required("This field is required."),
  emailSections: Yup.mixed().required("This field is required."),
  legal: Yup.boolean().oneOf([true], "You must accept these terms.").required(),
  crypto: Yup.boolean().optional(),
  pdfLinks: Yup.mixed(),
  singlePdf: Yup.boolean().required("This field is required"),
  singlePdfLink: Yup.string().optional(),
  scoringTypes: Yup.array<string>()
    // .min(1, "At least one email must be provided.")
    .optional(),
  CompetitiveLandscape: Yup.string().optional(),
  SalesChannels: Yup.string().optional(),
  MarketingPartners: Yup.string().optional(),
  SocialNetwork: Yup.string().optional(),
  marketSize: Yup.string().optional(),
  industryCagr: Yup.string().optional(),
  marketRisk: Yup.string().optional(),
  fundFromWhere: Yup.number().optional(),
  burnRate: Yup.number().optional(),
  CapitalSize: Yup.number().optional(),
  ExternalFundYear: Yup.number().optional(),
});
