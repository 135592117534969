import {
  ProjectActions,
  ProjectThunk,
  FormInfo,
  Invitation,
  ProjectAction,
  Project,
  ShareInfo,
  ShareReport,
  AddTeams,
  newScoringAssist
} from "../model";
import { History, LocationState } from "history";
import { ProjectRepo } from "../repositories/project";
import { array2Object, array2ObjectFunc } from "../utils";
import firebase, { firestore } from "firebase";
import { callableFunctions } from "../repositories/firebase";
// import { time } from "../pages/create/components/data";
// import {technicalScoringMarket} from "../pages/create/components/data";

// function _createPdfLinks(
//   sections: string[],
//   link?: string
// ): Record<string, string> {
//   if (!link) return {};
//   let temp: Record<string, string> = {};
//   for (const section of sections) temp[section] = link;
//   return temp;
// }


// const [showPopup, setShowPopup] = useState(false);

// export const projectCreate = (
//   form: FormInfo,
//   history: History<LocationState>,
//   technicalScoringFund: Array<Record<string, any>>,
//   technicalScoringMarket: Array<Record<string, any>>,
//   userdId: string,
//   pitchDeckUrl: string,
//   teamsData:Array<Record<string, any>>,
//   clickTypeSubmit:boolean,
//   id:number|undefined
// ): ProjectThunk => {
//   return async (dispatch) => {
//     // Dispatch the FETCH action to notify the state that fetching is in progress
//     dispatch({ type: ProjectActions.CREATE });
//     try {
//       // Call Firebase callable function to fetch all projects
//       localStorage.setItem("email-suggestions", JSON.stringify(form.emails));
//       localStorage.setItem("pref-weights", JSON.stringify(form.sectionWeights));
//      console.debug("project:form", form)
//      console.debug("project: technicalScoringFund", technicalScoringFund)
//      console.debug("project: technicalScoringMarket", technicalScoringMarket)
//      console.debug("project: userdId", userdId)
//      console.debug("project: pitchDeckUrl", pitchDeckUrl)
//      console.debug("project: teamsData", teamsData)
//      console.debug("project: clickTypeSubmit", clickTypeSubmit)
//      console.debug("project: id", id)
//     let res
//      const createProject = callableFunctions.httpsCallable('createProject');
//      if(id != undefined ){ 
//       res = await createProject({ form, technicalScoringFund, technicalScoringMarket, userdId, pitchDeckUrl, teamsData, clickTypeSubmit, id});
//     }else{
//       res = await createProject({ form, technicalScoringFund, technicalScoringMarket, userdId, pitchDeckUrl, teamsData, clickTypeSubmit});
//     }
//       console.debug("xasvhjashjhjas", res)

//       // Check if the result is an error
//    if (res instanceof Error){ 
//   dispatch({ type: ProjectActions.CREATE_ERROR, payload: res });
// }else {
  
//   if(clickTypeSubmit == true && res ){
//     dispatch({ type: ProjectActions.CREATE_SUCCESS, payload: res });
//     history.push(`/app/reports/${res.projectId}?showPopup=true`);
//   }
//   else{
//     history.push(`/app/drafts`);
//   }
//   // history.push(`/app/reports/${res}`);
// }
//     } catch (error) {
//       // Dispatch FETCH_ERROR action if any error occurs
//       dispatch({ type: ProjectActions.FETCH_ERROR, payload: error.message });
//     }
//   };
// }

// Modified validateNumericValues function to handle an object with nested properties
function validateNumericValues(data) {
  const isInvalid = (value) => value === Infinity || value === -Infinity || Number.isNaN(value);

  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'number' && isInvalid(data[key])) {
      console.error(`Invalid number value for key ${key}: ${data[key]}`);
      data[key] = 0; // or any default value you deem appropriate
    }
    // Recursively validate nested objects
    if (typeof data[key] === 'object' && data[key] !== null) {
      validateNumericValues(data[key]);
    }
  });
}

// Modified sanitizeData function to handle an object with nested properties
function sanitizeData(data) {
  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'object' && data[key] !== null) {
      sanitizeData(data[key]); // Recursively sanitize nested objects
    } else if (typeof data[key] === 'number' && (isNaN(data[key]) || data[key] === Infinity || data[key] === -Infinity)) {
      console.warn(`Sanitized invalid value at key ${key}, setting to 0`);
      data[key] = 0; // Replace invalid numbers with a default value
    }
  });
}


export const projectCreate = (
  form: FormInfo,
  history: History<LocationState>,
  technicalScoringFund: Array<Record<string, any>>,
  technicalScoringMarket: Array<Record<string, any>>,
  userdId: string,
  pitchDeckUrl: string,
  teamsData:Array<Record<string, any>>,
  clickTypeSubmit:boolean,
  id:number|undefined
): ProjectThunk => {
  return async (dispatch) => {
    dispatch({ type: ProjectActions.CREATE });

    try {
      // Save data to local storage
      localStorage.setItem("email-suggestions", JSON.stringify(form.emails));
      localStorage.setItem("pref-weights", JSON.stringify(form.sectionWeights));
      // console.debug("Project creation data:", );

      // Sanitize project data before passing to callable function
   
      console.debug("Original projectData before sanitization:", { form, technicalScoringFund, technicalScoringMarket, userdId, pitchDeckUrl, teamsData, clickTypeSubmit, id });
      sanitizeData(  {form,
        technicalScoringFund,
        technicalScoringMarket,
        userdId,
        pitchDeckUrl,
        teamsData,
        clickTypeSubmit,
        id});
      validateNumericValues( {form,
        technicalScoringFund,
        technicalScoringMarket,
        userdId,
        pitchDeckUrl,
        teamsData,
        clickTypeSubmit,
        id});

      
        console.debug("after projectData before sanitization:", { form, technicalScoringFund, technicalScoringMarket, userdId, pitchDeckUrl, teamsData, clickTypeSubmit, id });
      // Call Firebase Callable Function
      const createProject = callableFunctions.httpsCallable('createProject');
      const res = await createProject({  form,
        technicalScoringFund,
        technicalScoringMarket,
        userdId,
        pitchDeckUrl,
        teamsData,
        clickTypeSubmit,
        id});
      
      console.debug("Response from createProject:", res);

      // Handle the response
      if (res && res.data) {
        if (res.data.error) {
          console.error("Error from callable function:", res.data.error);
          dispatch({ type: ProjectActions.CREATE_ERROR, payload: res.data.error });
        } else {
          dispatch({ type: ProjectActions.CREATE_SUCCESS, payload: res.data });
          if (clickTypeSubmit && res.data) {
            history.push(`/app/reports/${res.data.projectId}?showPopup=true`);
          } else {
            history.push(`/app/drafts`);
          }
        }
      } else {
        console.error("Unexpected response format:", res);
        dispatch({ type: ProjectActions.CREATE_ERROR, payload: "Unexpected response format." });
      }
    } catch (error) {
      console.error("Error during project creation:", error);
      dispatch({ type: ProjectActions.CREATE_ERROR, payload: error.message });
    }
  };
};





function technicalAnalysis(form, technicalScoringFund, technicalScoringMarket) {
  var technical = {};
  technicalScoringMarket.forEach(element => {
    technical[element.value] = form[element.value] ? parseInt(form[element.value].split("|")[0]) : 0
  });
  technicalScoringFund.forEach(element => {
    technical[element.value] = form[element.value] ? form[element.value] : 0
  });
  return technical;
}


// export const fetchAllProjects = (): ProjectThunk => {
//   return async (dispatch, getState) => {
//     const { uid, email } = getState().firebase.auth;
//     dispatch({ type: ProjectActions.FETCH });
//     const res = await ProjectRepo.fetchAll(uid, email);
//     // console.debug(res,"prject action response")
//     if (res instanceof Error)
//       dispatch({ type: ProjectActions.FETCH_ERROR, payload: res });
//     else dispatch({ type: ProjectActions.FETCHED_ALL, payload: res });
//   };
// };


// export const fetchAllProjects = (): ProjectThunk => {
//   return async (dispatch, getState) => {
//     const { uid, email } = getState().firebase.auth;

//     // Dispatch the FETCH action to notify the state that fetching is in progress
//     dispatch({ type: ProjectActions.FETCH });

//     try {
//       // Call Firebase callable function to fetch all projects
//       const fetchProjects = firebase.functions().httpsCallable('fetchAllProject');
//       const res = await fetchProjects({ uid, email });
//       console.debug("xasvhjashjhjas", res)

//       // Check if the result is an error
//       if (res.data instanceof Error) {
//         dispatch({ type: ProjectActions.FETCH_ERROR, payload: res.data.message });
//       } else {
//         const projects: Project[] = res.data;
//         dispatch({ type: ProjectActions.FETCHED_ALL, payload: projects });
//       }
//     } catch (error) {
//       // Dispatch FETCH_ERROR action if any error occurs
//       dispatch({ type: ProjectActions.FETCH_ERROR, payload: error.message });
//     }
//   };
// };


export const fetchAllProjects = (limit: number | null, nextPageToken: string | null = null, filter:string| null = null, sortBy:string| null =null, sortingType:string|null =null): ProjectThunk => {
  return async (dispatch, getState) => {
    const { uid, email } = getState().firebase.auth;

    // Dispatch the FETCH action to notify the state that fetching is in progress
    dispatch({ type: ProjectActions.FETCH, payload: { loading: true } });

    try {
      // Call Firebase callable function to fetch all projects
      const fetchProjects = firebase.functions().httpsCallable('fetchAllProject');
      const res = await fetchProjects({ uid, email, pageToken: nextPageToken, limit: limit, filter:filter, sortBy , sortingType});

      // Debugging response
      console.debug("Response from fetchAllProject", res);

      // Check if the result contains an error message
      if (res.data instanceof Error) {
        dispatch({ type: ProjectActions.FETCH_ERROR, payload: { error: res.data.message } });
      } else {
        const { projects, nextPageToken: newNextPageToken, hasMore } = res.data;

        // Dispatch action to append the new projects to the existing list
        dispatch({
          type: ProjectActions.FETCHED_ALL,
          payload: {
            filter,
            sortBy,
            sortingType,
            projects,
            nextPageToken: newNextPageToken,
            hasMore,
            loading: false,
            
                                                                                                                                                                                                                                                    
            
          }
        });
      }
    } catch (error) {
      // Dispatch FETCH_ERROR action if any error occurs
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: { error: error.message } });
    }
  };
};


export const fetchSearchProjects = (limit: number | null, nextPageToken: string | null = null, searchTerm: string | null = null): ProjectThunk => {
  return async (dispatch, getState) => {
    const { uid, email } = getState().firebase.auth;

    // Dispatch the FETCH action to notify the state that fetching is in progress
    dispatch({ type: ProjectActions.FETCH, payload: { loading: true } });

    try {
      // Call Firebase callable function to fetch all projects
      const searchProjects = firebase.functions().httpsCallable('searchProjects');
      const res = await searchProjects({ pageToken: nextPageToken, limit: limit, searchTerm: searchTerm});

      // Debugging response
      console.debug("Response from fetchAllProject", res);

      // Check if the result contains an error message
      if (res.data instanceof Error) {
        dispatch({ type: ProjectActions.FETCH_ERROR, payload: { error: res.data.message } });
      } else {
        const { projects, nextPageToken: newNextPageToken, hasMore } = res.data;

        // Dispatch action to append the new projects to the existing list
        dispatch({
          type: ProjectActions.FETCHED_ALL,
          payload: {
            projects,
            nextPageToken: newNextPageToken,
            hasMore,
            loading: false,
            
                                                                                                                                                                                                                                                    
            
          }
        });
      }
    } catch (error) {
      // Dispatch FETCH_ERROR action if any error occurs
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: { error: error.message } });
    }
  };
};



export const fetchAllDraftProjects = (): ProjectThunk => {
  return async (dispatch, getState) => {
    const { uid, email } = getState().firebase.auth;
    dispatch({ type: ProjectActions.FETCHED_DRAFT });
    const res = await ProjectRepo.fetchAllDrafts(uid, email);
    // console.debug("prject action response", res)
    if (res instanceof Error)
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: res });
    else dispatch({ type: ProjectActions.FETCHED_DRAFT, payload: res });
  };
};

//export const addFavourite = (projectId: string,): ProjectThunk => {
export const addFavourite = (projectId: string,) => {
  return async (dispatch, getState) => {
    const { uid } = getState().firebase.auth;
    // console.debug(uid,"uuid")
    // console.debug(projectId,"projectId")
    const data = {
      UID: uid,
      projectid: projectId,
    }
    const res = await ProjectRepo.addToFavrourite(data);
    // console.debug(res,"project addFavourite action response")
    if (res instanceof Error)
      dispatch({ type: ProjectActions.ADD_FAVOURITE_ERROR, payload: res });
    else
      dispatch({ type: ProjectActions.ADD_FAVOURITE, payload: projectId });
  };
};

//export const addFavourite = (projectId: string,): ProjectThunk => {
export const removeFavourite = (projectId: string,) => {
  // console.debug("remove fav action")
  return async (dispatch, getState) => {
    const { uid } = getState().firebase.auth;
    const data = {
      UID: uid,
      projectid: projectId,
    }
    const res = await ProjectRepo.removeToFavrourite(data);
    // console.debug(res,"project removeFavourite action response")
    if (res instanceof Error)
      dispatch({ type: ProjectActions.REMOVE_FAVOURITE_ERROR, payload: res });
    else
      dispatch({ type: ProjectActions.REMOVE_FAVOURITE, payload: projectId });
  };
};

export const fetchReport = (
  projectId: string,
  project?: Project
): ProjectThunk => {
  return async (dispatch) => {
    dispatch({ type: ProjectActions.FETCH });
    const res = await ProjectRepo.fetchReport(projectId, project);
    if (res instanceof Error)
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: res });
    else dispatch({ type: ProjectActions.FETCHED_REPORT, payload: res });
  };
};

export const deleteProject = (projectId: string): ProjectThunk => {
  return async (dispatch) => {
    dispatch({ type: ProjectActions.DELETE });
    const err = await ProjectRepo.deleteProject(projectId);
    if (err) dispatch({ type: ProjectActions.DELETE_ERROR, payload: err });
    else {
      dispatch({ type: ProjectActions.DELETED_PROJECT, payload: projectId });
      if (window.location.pathname !== "/app/dashboard")
        window.location.assign("/");
    }
  };
};

export const incrementProjectViews = (
  projectId: string,
  section: string
): ProjectThunk => {
  return async () => {
    const res = await ProjectRepo.incrementViews(projectId, section);
    if (res) console.error(res);
  };
};

export const shareProject = (
  project: Project,
  info: ShareInfo
): ProjectThunk => {
  return async (dispatch, getState) => {
    const user = getState().firebase.auth;
    dispatch({ type: ProjectActions.SHARE });
    const err = await ProjectRepo.shareProject(
      project,
      info,
      user.displayName!,
      user.uid
    );
    if (err) dispatch({ type: ProjectActions.SHARE_ERROR, payload: err });
    else dispatch({ type: ProjectActions.SHARED_PROJECT });
  };
};

export const shareProjectReport = (
  project: Project,
  info: ShareReport
): ProjectThunk => {
  return async (dispatch, getState) => {
    const user = getState().firebase.auth;
    dispatch({ type: ProjectActions.SHARE });
    const err = await ProjectRepo.shareProjectReport(
      project,
      info,
      user.displayName!,
      user.uid
    );
    if (err) dispatch({ type: ProjectActions.SHARE_ERROR, payload: err });
    else dispatch({ type: ProjectActions.SHARED_REPORT });
  };
};

export const resetProjectModal = (): ProjectAction => ({
  type: ProjectActions.RESET_MODAL,
});
