import axios from 'axios';

export const pdfData=(headerTitle, cardHeading1, cardHeading2, cardHeading3, cardHeading4, cardData1, cardData2, cardData3, cardData4, imageLink1, imageLink2) =>{

    let data={
    "heading": headerTitle,
    "cardHeading1": cardHeading1,
    "cardData1": convertDataImg(cardData1),
    "cardHeading2": cardHeading2,
    "cardData2": convertDataImg(cardData2),
    "cardHeading3": cardHeading3,
    "cardData3": convertDataImg(cardData3),
    "cardHeading4": cardHeading4,
    "cardData4": convertDataImg(cardData4),
    "imageLink1": imageLink1,
    "imageLink2": imageLink2,
  }
  let imgArray = [];
  if (data.imageLink1) {
    imgArray.push(data.imageLink1);
  }
  if (data.imageLink2) {
    imgArray.push(data.imageLink2);
  }
  Promise.all(imgArray.map(imgUrl => convertImageUrlToBlobTestGiveHtml(imgUrl)))
    .then(convertedImages => {
      data.images = convertedImages;
    });

    return data

    }

    export const elevatorPitchData = (headerTitle, elevatorData) => {
      let data={
        "heading": headerTitle,
        "cardData": convertDataImg(elevatorData),
      }
      return data

    }

    export const companyPitchData = (companyImage, companyDescription, companyName, companyStage, companySector, companyWebsite ) => {
      let company = {
        "companyImage": companyImage,
        "companyDescription": companyDescription,
        "companyName": companyName,
        "companyStage": companyStage,
        "companySector": companySector,
        "companyWebsite": companyWebsite,
      }
      if (companyImage != "") {
        convertImageUrlToBlobTestGiveHtml(companyImage).then(r => {
          company.image = r;
        });
      }
      return company

    }

    function convertDataImg(data) {  //function for fixing the size of image in pitch deck project document

        const containsImage = data.includes('<img');
    
        // Set the width and height if it contains an image
        if (containsImage) {
          const modifiedHtml = addWidthAndHeightToImage(data, 250, 250); // Set your desired width and height
          return modifiedHtml;
        } else {
          return data
        }
    
      }

      function addWidthAndHeightToImage(htmlString, width, height) { // function for fixing the size of image in pitch deck project document
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const imgTag = doc.querySelector('img');
    
        if (imgTag) {
          imgTag.setAttribute('width', width);
          imgTag.setAttribute('height', height);
        }
    
        return doc.documentElement.innerHTML;
      }

      async function convertImageUrlToBlobTestGiveHtml(imageUrl) {
        console.debug("convertImageUrlToBlobTestGiveHtml", imageUrl)
        try {
          const response = await axios.get(imageUrl, { responseType: 'blob',   mode: 'no-cors' });
    
          if (response.status === 200) {
            const blobUrl = URL.createObjectURL(response.data);
    
            return blobUrl;
          } else {
            throw new Error('Image fetch failed');
          }
        } catch (error) {
          return null;
        }
      }
