import { makeStyles } from "@material-ui/styles";

const logoSize = 40;
export default makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: theme.spacing(12),
  },
  fab: {
    position: "fixed",
    background:'#137DFB !important',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: "white",
    zIndex: 1000,
  },
  gridContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    // gridAutoFlow: "row dense",
    // gridGap: theme.spacing(2),
    // // gridTemplateColumns: "repeat(auto-fit, 400px)",
    // gridTemplateColumns: "auto auto auto",
    // marginBottom: theme.spacing(3),
  },
  selectInput: {
    padding: 10,
    paddingRight: 25,
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  search: {
    maxWidth: 300,
    boxShadow: "none",
    background: theme.palette.background.default,
  },
  companyLogo: {
    width: logoSize,
    height: logoSize,
    borderRadius: 8,
    objectFit: "contain",
  },

  typo: {
    color: theme.palette.text.dark,
    fontFamily: "'Manrope', sans-serif",
    fontSize: "calc(1.1rem) !important",
    marginTop:"1rem",
    display: "inline-block",
    marginRight: "0.5rem",
    whiteSpace: "pre-wrap",

    "& > span":{
    paddingLeft: 5

    }

  },
  circleRank:{
    // border: "1px solid",
    height: "40px",
    width: "40px",
    textAlign: "center",
    paddingTop: "8px",
    borderRadius: "100%",
    display: "inline-block",
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "600",
    marginRight: "0.5rem",
    "& >img":{
      width: "100%"
    }
  },
  rankedContent:{
    // display: "flex",
    // alignItems: "center",
    marginRight: "5rem"
  },
  disabledImage: {
    opacity: "0.3"
  },
  labelHead:{
    fontWeight: "600"
  },
  paper: {
    position: "relative",
    textAlign: "center",
    paddingTop: theme.spacing(20),
    color: theme.palette.text.secondary,
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 0px 8px 1px #ccc",
    // width: 330,
    "&:hover": {
      cursor: "pointer",
    },
    
    margin: theme.spacing(2),
    width: 315,
    height: theme.spacing(40),
    right: 15,
  },
  
}));
