export enum ViewType {
  grid,
  list,
}

// export enum SortingType {
//   default = "company.name|asc",
//   defaultReversed = "company.name|desc",
//   // ranking = "sectionStats.overall|desc",
//   // technicalRanking = "technical.overall|desc",
//   overallRanking = "overall|desc",
//   overallRankingReverse = "overall|asc",
//   // rankingReversed = "sectionStats.overall|asc",
//   sector = "company.sector|asc",
//   date = "startDate|asc",
//   dateReversed = "startDate|desc",
//   likes = "company.name|asc|myLikes",
//   shares = "company.name|asc|sharedWithMe",
//   uploads = "company.name|asc|myUploads",
// }

export enum Filter {
  likes = "myLikes",
  shares = "sharedWithMe",
  uploads = "myUploads",
  draft = "draft",
  overall = "overall",
}

export enum ScoreType {
  default = "overall",
  technical = "technical",
  expert = "expert",
  rapid = "rapid",
}

export enum SortingType {
   nameAsc='atoz',
  nameDesc='ztoa',
  highToLow='highToLow',
  lowToHigh='lowToHigh',
  dateDesc='dateDesc',
  dateAsc='dateAsc'

  
}
