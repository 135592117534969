import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Button, CardActions, Box, Paper, Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import useStyles from "./Problem/styles";
import { SubscriptionDispatch } from '../../../model';
import { fetchSubscription } from '../../../actions/subscription';
import { callableFunctions } from '../../../repositories/firebase';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Loader from '../../../components/Loader/Loader';
import { IconButton, Modal } from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import FutureProofingStartupsDialog from '../../../components/Modals/FutureProofingStartupsDialog';
import ApplyCouponDialog from '../../../components/Modals/ApplyCouponDialog';
import { app } from "../../../repositories/firebase";


function getModalStyle() {
  const width = 100;
  const height = 100;
  return {
    width: `${width}%`,
    height: `${height}%`,
    overflow: 'auto',
    // transform: `translate(-${width}%, -${height}%)`,
  };
}
function SubscriptionCard(props: any) {
  const [loading, setLoading] = useState(false);
  const [openPlanPage, setOpenPlanPage] = useState(false);
  const [openFutureProofingPopup, setOpenFutureProofingPopup] = useState(false)
  const [modalStyle] = React.useState(getModalStyle);
  const [coupoun, setCoupoun] = useState('');
  const currentPlan = useSelector((state: RootState) => state.firebase?.profile?.currentPlan);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  // console.debug("sahjhvjc", app)
  // const publishableKey = "pk_test_Md6tQuscaC9rKSIB0Pqlizj1"
  // const stripePromise = loadStripe('pk_test_Md6tQuscaC9rKSIB0Pqlizj1');
  // const stripe = new Stripe('pk_test_Md6tQuscaC9rKSIB0Pqlizj1');
  let history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch<SubscriptionDispatch>();
  const onOpenFutureProofingPopup = () => {
    setOpenFutureProofingPopup(true)
  };
  const [openApplyCoupon, setOpenApplyCoupon] = React.useState(false);
  const [planObject, setPlanObject] = React.useState();
  const [createNowLoading, setCreateNowLoading] = useState(false);
  const subscriptionPlans = useSelector((state: RootState) => state.subscription.allSubscription);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleOpenApplyCoupon = (plan) => {
    setOpenApplyCoupon(true)
    if (plan) {
      setPlanObject(plan)
    }
  };
  const handleCloseApplyCoupon = () => setOpenApplyCoupon(false);

  const handleSubmit = async (planId: string) => {
    console.debug("bhshjbsxsaxjk:planId", planId)
    setLoading(true);
    setOpenApplyCoupon(false);

    try {
        const createCheckoutSession = callableFunctions.httpsCallable('createCheckoutSession');
        const result = await createCheckoutSession({ priceId: planId, couponCode: coupoun }); // Fixed typo: coupoun -> coupon
        const sessionId = result.data.sessionId;

        const stripe = await loadStripe(app.options.stripePublishKey);

        if (stripe && sessionId) {
            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) {
                console.error("Stripe redirect error:", error);
                setLoading(false);
            }
        } else {
            console.error("Stripe not initialized or sessionId missing.");
            setLoading(false);
        }
    } catch (error) {
        console.error("Error during checkout:", error);
        setLoading(false);
    }
};




  const getFormateDate = (duration) => {
    const planExpireDate = new Date();
    switch (duration) {
      case '30 Days':
        planExpireDate.setDate(planExpireDate.getDate() + 30);
        break;
      case '3 Months':
        planExpireDate.setMonth(planExpireDate.getMonth() + 3);
        break;
      case '12 Months':
        planExpireDate.setMonth(planExpireDate.getMonth() + 12);
        break;
      default:
        throw new Error('Invalid plan duration');
    }
    return planExpireDate;
  };
  const updateCurrentPlanWithUser = async (id, plan) => {
    try {
      // if (id == "1Faq1IPGLtvhpDnXmkwa") {
      const planData = {
        stripPlanId: id,
        accessDuration: plan.accessDuration,
        duration: plan.accessDuration,
        name: plan.name,
        // planStatus: 'activated',
        planExpireDate: getFormateDate(plan.accessDuration).toISOString(),
        shareProject: plan.share,
        sharedProject: 0,
        uploadProject: plan.uploadProject,
        uploadedProject: 0,
      };
      console.debug("purchasedPlan:planData", planData)
      const updateUserPlan = callableFunctions.httpsCallable('updateUserPlan');
      return await updateUserPlan(planData);
      //   history.push("/app/dashboard")
     
    } catch (error) {
      console.error('Error adding custom object:', error);
    }
  };

 const handleSubmitFree = async (planId: string) => {
    setCreateNowLoading(true);
    const purchasedPlan = subscriptionPlans.find(o => o.stripePaymentId === "Null");

    if (subscriptionPlans && purchasedPlan) {
      try {
        const response = await updateCurrentPlanWithUser(purchasedPlan.id, purchasedPlan);
        if (response?.data.success === true) {
          setSnackbarMessage('Plan updated successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);

          setTimeout(() => {
            if (window.location.pathname === '/create') {
              redirectToHome();
            } else {
              redirectToHome();
              history.push('/app/dashboard');
            }
          }, 4000);
        } else {
          setSnackbarMessage('Failed to update plan.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error('Error updating plan:', error);
        setSnackbarMessage('An error occurred. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }

    setCreateNowLoading(false);
  };

  const redirectToHome = () => {
    props.setOpenPlan(false)
  }

  React.useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch]);

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={classes.pitchDeckPaper} style={modalStyle}>
          {loading == false ?
            <div>
              <IconButton
                edge="start"
                className={classes?.back}
                color="inherit"
                aria-label="menu"
                onClick={redirectToHome}
              >
                <Close />
              </IconButton>
              <Grid container justifyContent="center" alignItems="stretch" sx={{ background: "aliceblue", height: "100vh" }} >
                <Grid container justifyContent="center" alignItems="stretch" >
                  <Grid item xs={12} sm={6} md={6} xl={5.4} sx={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                    <Box sx={{ textAlign: "end", ml: { xl: "5rem" } }}>
                      <Typography variant='h5' sx={{ textAlign: "center" }}>Separate Signals from  Noise</Typography>
                      <Typography variant='h6' sx={{ textAlign: "center", fontStyle: 'italic' }} >for</Typography>
                      <Typography variant='h6' sx={{ textAlign: "center", fontStyle: 'italic' }} >Founders and Investors</Typography></Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} xl={6.6} sx={{ display: "flex", alignItems: "end" }}>
                    <Box sx={{
                      width: "41rem",
                      backgroundColor: '#137DFB',
                      textAlign: "center",
                      borderRadius: "5px",
                      position: "absolute"
                    }}>
                      <InfoOutlined className={classes.infoIcon} onClick={onOpenFutureProofingPopup} />
                      <Typography sx={{ fontSize: "20px", mx: "auto", color: "White" }}>All Plans include the i3D Protocol's</Typography>
                      <Typography display="block" variant="h6" component="h2" sx={{ color: "White" }}>FUTURE-PROOFING START-UPS   </Typography>
                      <Typography variant="body1" sx={{ fontSize: "18px", fontStyle: 'italic', color: "White" }} >A Masterclass in Growing & Investing in Start-ups</Typography>
                    </Box>
                    <FutureProofingStartupsDialog open={openFutureProofingPopup} setOpenFutureProofingPopup={setOpenFutureProofingPopup} />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="stretch"  >
                  {subscriptionPlans.length != 0 ? subscriptionPlans.filter((data) => data.planStatus === true).sort((a, b) => a.sortBy - b.sortBy).map((plan, index) => (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
                      <Card style={{ height: '540px', position: "relative", margin: "25px" }}>
                        <CardContent>
                          <Typography variant="h4" className={classes.planName} component="div" gutterBottom>
                            {plan?.name}
                          </Typography>
                          <Typography variant="h6" className={classes.planName} gutterBottom>
                            {plan?.price}
                          </Typography>
                          <Typography>
                            {plan?.description}
                          </Typography>
                          <ul className={classes.planList}>
                            {plan?.planFeature.map((feature, index) => (
                              <li key={index}>{feature}</li>
                            ))}
                          </ul>
                        </CardContent>
                        <CardActions className={classes.bottonBtn}>
                          <Box>
                            <Typography sx={{ paddingLeft: "15px", color: "#137DFB", fontWeight: 500 }}>{plan?.name === 'Start-up' ? "BONUS FOR PRO AND GLOBAL" : "BONUS"}</Typography>
                            <ul className={classes.bonus}>
                              <li >Create a Structured Pitch Deck giving investors the  essential data they need!</li>
                              <li >Data Room for up to 6 documents</li>
                            </ul>
                          </Box>
                          <Box className={classes.buttonBox}>
                            {plan?.name === 'Start-up' ? <> .</> : <Typography sx={{ color: "#137DFB", fontWeight: 500, cursor: "pointer" }} onClick={() => handleOpenApplyCoupon(plan)}>Apply Coupon</Typography>}
                            {plan?.name === 'Start-up' ? <Button variant="contained" className={classes.button} disabled={props.hide == true ||  (currentPlan?.name === 'Start-up' && currentPlan.uploadedProject === 1) ? true : false } onClick={() => handleSubmitFree(plan.stripePaymentId) }>
                            {createNowLoading == true ? "Creating..." : "Create Now"}
                            </Button> :
                              <Button variant="contained" className={classes.button} disabled={props.hide == true ? true : false } onClick={() =>  handleSubmit(plan.stripePaymentId)}>
                                Buy Now
                              </Button>
                            }
                          </Box>
                        </CardActions>
                      </Card>
                    </Grid>
                  )) : []}
                </Grid>
              </Grid>
            </div> : <Loader text="redirecting to merchant..." />}
        </Paper></Modal>
      <ApplyCouponDialog open={openApplyCoupon} setOpenPlan={props.setOpenPlan} setOpenApplyCoupon={setOpenApplyCoupon} coupoun={coupoun} setCoupoun={setCoupoun} planObject={planObject} handleClose={handleCloseApplyCoupon} buynow={handleSubmit} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>

  );
}
export default SubscriptionCard;
