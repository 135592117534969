import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useLocation,
} from "react-router-dom";
import Layout from "./Layout/Layout";
import axios from "axios";
import * as pages from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Loader from "./Loader/Loader";
import { UserDispatch } from "../model";
import { redirectToPlan } from "../actions/user";
import { setWalletOpen } from "../actions/metamask";
import PaymentSuccessFull from "../pages/create/components/PaymentSuccessFull/PaymentSuccessFull";
import { app, auth, messaging, functions, callableFunctions } from "../repositories/firebase"; // Ensure firebase functions are imported
declare var window: any;

const networkChanged = (chainId) => {
  console.log({ chainId });
};

export default function App() {
  const authtenication = useSelector((state: RootState) => state.firebase.auth);
  const user = useSelector((state: RootState) => state.user);
  const isAuthenticated = isLoaded(authtenication) && !isEmpty(authtenication);
  const dispatch = useDispatch();

  useEffect(() => {
    // Handle Ethereum events
    if (window.ethereum) {
      const handleDisconnect = (error: ProviderRpcError) => {
        window.location.reload();
        dispatch(setWalletOpen(true));
      };

      const handleChainChanged = () => {
        window.location.reload();
        return () => {
          dispatch(setWalletOpen(true));
          window.ethereum.removeListener("chainChanged", networkChanged);
        };
      };

      const handleAccountsChanged = () => {
        window.location.reload();
        dispatch(setWalletOpen(true));
      };

      window.ethereum.on('disconnect', handleDisconnect);
      window.ethereum.on('chainChanged', handleChainChanged);
      window.ethereum.on('accountsChanged', handleAccountsChanged);

      // Clean up the event listeners when the component unmounts
      return () => {
        window.ethereum.removeListener('disconnect', handleDisconnect);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      };
    }

    // Request FCM permission and store token if the user is authenticated
    if (isAuthenticated) {
      requestPermissionAndStoreToken(authtenication);
    }
  }, [authtenication, isAuthenticated, dispatch]);

  // Function to request FCM permissions and store the token
  const requestPermissionAndStoreToken = async (authtenication) => {
    try {
      await messaging.requestPermission();
      const fcmToken = await messaging.getToken();
      if (fcmToken) {
        console.debug("FCM Token:", fcmToken);
        await storeToken(fcmToken, authtenication); // Call storeToken function
      } else {
        console.warn('No FCM token retrieved');
      }
    } catch (error) {
      console.error('Error getting permission or token', error);
    }
  };

  // Function to store the token using Firebase Callable Functions
  const storeToken = async (fcmToken, authtenication) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true); // Firebase ID token
      const data = {
        fcmToken,
        userEmail: authtenication.email,
        userId: authtenication.uid,
        createdAt: new Date().toISOString(),
      };

      // Use Firebase Callable Function
      const storeNotificationToken = callableFunctions.httpsCallable('storeNotificationToken');
      const response = await storeNotificationToken({ ...data });

      console.debug('Success response:', response.data);
    } catch (error) {
      console.error('Error storing token:', error);
    }
  };

  // Render loading screen if authentication or payment state is not ready
  if (!isLoaded(auth)) return <Loader />;
  if (user.paymentLoading) return <Loader text="Redirecting to payment portal..." />;
   console.debug("user", user)
   console.debug("user:authtenication", isAuthenticated)

  return (
    <BrowserRouter>
      <Switch>
      <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
        <Route exact path="/subscription" component={Payment} />
        <Route path="/form" component={pages.Form} />
        <PrivateRoute path="/typeform/expertScore" component={pages.ExpertScore} />
        <PrivateRoute path="/typeform/technicalScore" component={pages.TechnicalScore} />
        <PrivateRoute path="/create" component={pages.Create} />
        <PrivateRoute path="/app/create/:id" component={pages.Create} />
        <PrivateRoute path="/test" component={pages.Test} />
        <PrivateRoute path="/payment-success/:planId" component={PaymentSuccessFull} />
        <PrivateRoute path="/payment-success" component={PaymentSuccessFull} />
        <PrivateRoute path="/createTechnicalScore" component={pages.CreateTechnicalScore} />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/forgotPassword" component={pages.ForgotPassword} />
        <PublicRoute path="/reset/newpassword" component={pages.ResetForgetPassword} />
        <PublicRoute path="/login" component={pages.Login} />
        <PrivateRoute path="/connect" component={pages.WalletConnect} />
        <PrivateRoute path="/overview/:id" component={pages.OverView} />
        <PrivateRoute path="/newscore/:id/:section" component={pages.NewScore} />
        <Route component={pages.Error} />
      </Switch>
    </BrowserRouter>
  );

  // PrivateRoute wrapper to handle protected routes
  function PrivateRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, { ...props })
          ) : (
            // Redirect to login, but store the current location in state to navigate back
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }, // Keep track of the attempted page
              }}
            />
          )
        }
      />
    );
  }
  
  // PublicRoute wrapper for routes accessible to unauthenticated users
  function PublicRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            // Redirect to the previous page or dashboard if no specific page was tried
            <Redirect
              to={props.location.state?.from || { pathname: "/app/dashboard" }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

// Payment component to handle subscription redirection
function Payment() {
  const location = useLocation();
  const dispatchPayment = useDispatch<UserDispatch>();
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get("plan");

  useEffect(() => {
    if (plan && !profile.plan) dispatchPayment(redirectToPlan(plan));
  }, [dispatchPayment, plan, profile]);

  return (
    <Redirect
      to={{
        pathname: `/login?plan=${plan}`,
        state: { from: location },
      }}
    />
  );
}
