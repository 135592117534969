import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { Typography } from "../../../components/Wrappers/Wrappers";
import useStyles from "../styles";
import { fetchReport } from "../../../actions/project";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../reducers";
import Loader from "../../../components/Loader/Loader";
import { fields } from "../../../pages/create/components/infos";
import DescriptionCard from "./DescriptionCard";
import CustomiseTooltip from "../../../components/Tooltip/CustomiseTooltip";
// import { info } from "../../../pages/create/components/infos";
import { headingInfo } from "../../../pages/create/components/infos"
import CompanyDetail from "./company";
import { callableFunctions } from "../../../repositories/firebase";
import TeamTable from "./TeamTable";



export default function ScoringAssist(props) {
    const [teamData, setTeamData] = useState([])

    const dispatch = useDispatch();
    const classes = useStyles();
    const id = props.id;
    const companyData = props.companyData
    const project = useSelector((state: RootState) => state.project)
    useEffect(() => {
        if (id) {
            //   const requested = allProjects.find((p) => p.id === id);
            dispatch(fetchReport(id));
            getTeamDataCallableFunction(id)
        }
    }, []);
    const tokenomicsDataforScoringAsist = project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist : ""

    const getTeamDataCallableFunction = async (id) => {
        const getProjectTeamMemberDetail = callableFunctions.httpsCallable('getProjectTeamMemberDetail')
        const result = await getProjectTeamMemberDetail({ projectId: id })
        // console.debug("TeamData", result.data.teamData)
        setTeamData(result.data.teamData)
    }
    if (project.fetching)
        return <Loader text="Fetching your report..." />

    const imageLink = project.focusedReport?.project.imageLinks
    return (
        <div className=''>
            <CompanyDetail data={companyData} />
            <Grid item xs={12}  className={classes.parentElivatorPitch}>
                <Typography variant='h6' className={classes.pageTitle}>
                    Elevator Pitch
                    {/* <CustomiseTooltip title={headingInfo.problemDefinition} className="text-white" /> */}
                </Typography>
                {/* {console.debug("project",project)} */}
                <Grid    className={classes.parentGridElivatorPitch}>
                        <Paper elevation={2} variant="outlined" className={classes.elivatorPitchCard}>
                            {/* Hello */}
                            <span dangerouslySetInnerHTML={{
                                __html: project.focusedReport? project.focusedReport.project ? project.focusedReport.project.company ? project.focusedReport.project.company.rapidDescription.replace(/<br\s*\/?>/gi, ' ').replace(/&(nbsp|amp|quot|lt|gt);/g, " "):"":"":""
                            }}>
                            </span>
                        </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Typography variant='h6' className={classes.pageTitle}>
                        Problem Definition
                        <CustomiseTooltip title={headingInfo.problemDefinition} className="text-white" />
                    </Typography>
                </Grid>
                {fields.problem.map((val) => {
                    return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} />
                })}
                <div className={classes.gridContainer}>
                    {imageLink && imageLink.problem1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.problem1 : ''} className={classes.img} alt="images"/>}
                    {imageLink && imageLink.problem2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.problem2 : ''} className={classes.img} alt="images"/>}
                </div>


                {/* This is Solution Cards*/}
                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.pageTitle}>
                        Solution Proposed
                        <CustomiseTooltip title={headingInfo.solutionProposed} className="text-white" />
                    </Typography>
                </Grid>
                {fields.solution.map((val) => {
                    return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} />
                })}
                {/* {console.debug("project.imageLinks :solution",imageLink.solution1)} */}

                <div className={classes.gridContainer}>
                    {imageLink && imageLink.solution1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.solution1 : ''} className={classes.img}  alt="images"/>}
                    {imageLink && imageLink.solution2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.solution2 : ''} className={classes.img}  alt="images"/>}
                </div>




                {/* This is Market Cards*/}
                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.pageTitle}>
                        Market Potential
                        <CustomiseTooltip title={headingInfo.marketPotential} className="text-white" />
                    </Typography>
                </Grid>
                {fields.market.map((val) => {
                    return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} />
                })}

                <div className={classes.gridContainer}>
                    {imageLink && imageLink.market1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.market1 : ''} className={classes.img} alt="images" />}
                    {imageLink && imageLink.market2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.market2 : ''} className={classes.img} alt="images" />}
                </div>




                {/* This is Business Cards*/}
                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.pageTitle}>
                        Business Model
                        <CustomiseTooltip title={headingInfo.businessModel} className="text-white" />
                    </Typography>
                </Grid>
                {fields.business.map((val) => {
                    return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} />
                })}

                <div className={classes.gridContainer}>
                    {imageLink && imageLink.business1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.business1 : ''} className={classes.img} alt="images"/>}
                    {imageLink && imageLink.business2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.business2 : ''} className={classes.img} alt="images"/>}
                </div>
                {/* This is GoToMarket Cards*/}
                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.pageTitle}>
                        Go To Market
                        <CustomiseTooltip title={headingInfo.goToMarket} className="text-white" />
                    </Typography>
                </Grid>
                {fields.gotomarket.map((val) => {
                    return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} />
                })}

                <div className={classes.gridContainer}>
                    {imageLink && imageLink.goto1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.goto1 : ''} className={classes.img} alt="images"/>}
                    {imageLink && imageLink.goto2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.goto2 : ''} className={classes.img} alt="images"/>}
                </div>



                {/* This is Milestones Cards*/}
                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.pageTitle}>
                        Traction And Milestones
                        <CustomiseTooltip title={headingInfo.tractionAndMilestones} className="text-white" />
                    </Typography>
                </Grid>
                {fields.milestones.map((val) => {
                    return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} />
                })}

                <div className={classes.gridContainer}>
                    {imageLink && imageLink.milestone1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.milestone1 : ''} className={classes.img} alt="images"/>}
                    {imageLink && imageLink.milestone2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.milestone2 : ''} className={classes.img} alt="images"/>}
                </div>



                {/* This is funds Cards*/}

                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.pageTitle}>
                        Funding and Ask
                        <CustomiseTooltip title={headingInfo.fundingAndAsk} className="text-white" />
                    </Typography>
                </Grid>
                {fields.funding.map((val) => {
                    return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} tooltip={true} />
                })}

                <div className={classes.gridContainer}>
                    {imageLink && imageLink.fund1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.fund1 : ''} className={classes.img} alt="images"/>}
                    {imageLink && imageLink.fund2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.fund2 : ''} className={classes.img} alt="images"/>}
                </div>

                {/* This is Competition Cards*/}
                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.pageTitle}>
                        Competitive Landscape
                        <CustomiseTooltip title={headingInfo.competitiveLandscape} className="text-white" />
                    </Typography>
                </Grid>
                {fields.competition.map((val) => {
                    return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} />
                })}

                <div className={classes.gridContainer}>
                    {imageLink && imageLink.competition1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.competition1 : ''} className={classes.img} alt="images"/>}
                    {imageLink && imageLink.competition2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.competition2 : ''} className={classes.img} alt="images"/>}
                </div>
                {/* This is Tokenomics Cards*/}

                {/* {console.debug("fields.tokenomics",tokenomicsDataforScoringAsist)} */}
                {/* {console.debug("fields",fields)} */}
                {tokenomicsDataforScoringAsist.tokenDistribution != "" && tokenomicsDataforScoringAsist.tokenSale != "" && tokenomicsDataforScoringAsist.tokenUtility != "" && tokenomicsDataforScoringAsist.incentives != "" ?

                    <div>
                        <Grid item xs={12}>
                            <Typography variant='h6' className={classes.pageTitle}>
                                Tokenomics
                                <CustomiseTooltip title={headingInfo.tokenomics} className="text-white" />
                            </Typography>
                        </Grid>

                        {fields.tokenomics.map((val) => {
                            return <DescriptionCard label={val.label} description={project.focusedReport?.project.scoringAssist ? project.focusedReport?.project.scoringAssist[val.value] : ""} />
                        })}

                        <div className={classes.gridContainer}>
                            {imageLink && imageLink.token1 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.token1 : ''} className={classes.img} alt="images"/>}
                            {imageLink && imageLink.token2 && <img src={project.focusedReport?.project.imageLinks ? project.focusedReport?.project.imageLinks.token2 : ''} className={classes.img} alt="images"/>}
                        </div>
                    </div>
                    : ""}
                <Grid item xs={12}>
                    <Typography variant='h6' className={classes.pageTitle}>
                        Team
                        {/* <CustomiseTooltip title={headingInfo.tokenomics} className="text-white" /> */}
                    </Typography>
                </Grid>
                {teamData.map((val) =>
                    <TeamTable data={val} />
                )}
            </Grid>
        </div >
    );
}
