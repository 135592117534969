import React, { Dispatch, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

import useStyles from "./styles";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Project, LayoutAction, ModalType } from "../../model";
import {
  getSectorLabel,
  getStageLabel,
  overallScore,
} from "../../pages/create/components/data";
//import { ReportMenu } from "../Modals/Menu/Menu";
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useSelector, useDispatch } from "react-redux";
// import { fetchAllProjects } from "../../actions/project";
import { addFavourite, removeFavourite } from "../../actions/project";
import { shareUpgradeModal, showModal } from "../../actions/layout";
import { RootState } from "../../reducers";
import { ScoreType } from "../../utils/enums";
import LockIcon from '@material-ui/icons/Lock';
import SubscriptionCard from "../../pages/create/components/plan";
interface IProps {
  project: Project;
  filter: string;
  top?: boolean;
}
// import {initializeUser} from "../../actions/user";


function ProjectCard({ project, filter,  scoreSort,top }: IProps) {
  const classes = useStyles();
  const dispatchAddFavourite = useDispatch();
  const dispatchRemoveFavourite = useDispatch();
  const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
  const walletOpen = useSelector((state: RootState) => state.metamask.walletOpen);
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const [openPlan, setOpenPlan] = useState(false)

  const plan = profile.currentPlan;
  // console.debug("plan", plan)

  const upgradePlanToShare = (e) => {
    e.preventDefault();
    return dispatchLayout(shareUpgradeModal());
  }

  const goToPlan = (e) => {
    e.preventDefault();
    console.debug("ismein gaya", openPlan)
    setOpenPlan(true)
    // history.push("/plan")
  }
  const addToFav = (e, projectId) => {
    e.preventDefault();
    dispatchAddFavourite(addFavourite(projectId));
  }

  // console.debug("profileSectionInCard:Share", profile)
  // const removeToFav = (e,projectId) =>{
  const removeToFav = (e) => {
    e.preventDefault();
    console.debug("remove fav click hadnler")
    dispatchRemoveFavourite(removeFavourite(project.id));
  }

  const shareReport = (e) => {
    e.preventDefault();
    return dispatchLayout(showModal(ModalType.SHAREREPORT, project));
  }

  function getScoreText(project) {
    switch (scoreSort) {
      case ScoreType.expert:
        return (
          <Typography variant="body1" color="text" brightness="main">
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              {((project.sectionStats.overall ? project.sectionStats.overall.avgRating : 0) * 100).toFixed(0)}
            </span>
            <span style={{ fontSize: "12px" }}>
              % Expert Score
            </span>
          </Typography>
        );
      case ScoreType.technical:
        return (
          <Typography variant="body1" color="text" brightness="main">
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              {((project.technical ? project.technical.overall : 0) * 100).toFixed(0)}
            </span>
            <span style={{ fontSize: "12px" }}>
              % Technical Score
            </span>
          </Typography>
        );
      case ScoreType.rapid:
        return (
          <Typography variant="body1" color="text" brightness="main">
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              {((project.sectionStats.i3DRapid ? project.sectionStats.i3DRapid.avgRating : 0) * 100).toFixed(0)}
            </span>
            <span style={{ fontSize: "12px" }}>
              % Rapid Score
            </span>
          </Typography>
        );
      // case SortingType.rankingReversed:
      // 	break;
      default:
        return (
          <Typography variant="body1" color="text" brightness="main">
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              {overallScore(project).toFixed(0)}
            </span>
            <span style={{ fontSize: "12px" }}>
              % Overall Score
            </span>
          </Typography>
        );
        break;
    }

  }
console.debug("!project", filter)

  if (!plan) {
    console.debug("if mein plan",plan)
    return (
  
        <Paper variant="outlined" className={classes.paper}>
          {/* <Link
            // to={`/connect`}
            to=""
            style={{ display: "flex", flexDirection: "column" }}> */}
            <div onClick={goToPlan}>
            <div className={classes.wrapper}  >
              <span className={classes.interactions}>
                {/* <span onClick={addToFav(project.id)}> */}
                {filter === 'draft'?"":
                  project.likes === true ? (
                    plan ? (
                      (plan.shareProject !== plan.sharedProject && plan.shareProject >= plan.sharedProject) || plan.shareProject === "no-limit" ?
                        <FavoriteIcon onClick={removeToFav} fontSize="small" />
                        : <FavoriteIcon fontSize="small"  onClick={upgradePlanToShare} />
                    ) : (
                      <FavoriteIcon fontSize="small"  onClick={goToPlan} />
                    )
                  ) : (
                    plan ? (
                      (filter === 'draft'?"": plan.shareProject !== plan.sharedProject && plan.shareProject >= plan.sharedProject) || plan.shareProject === "no-limit" ?
                        <FavoriteBorderOutlinedIcon onClick={(e) => { addToFav(e, project.id) }} fontSize="small" />
                        : <FavoriteBorderOutlinedIcon fontSize="small" style={{ color: "#858C94" }} onClick={upgradePlanToShare} />
                    ) : (
                      <FavoriteBorderOutlinedIcon fontSize="small" style={{ color: "#858C94" }} onClick={goToPlan} />
                    )

                  )
                }
                {filter === 'draft'?"": plan ? ((plan.shareProject != plan.sharedProject && plan.shareProject >= plan.sharedProject) || plan.shareProject == "no-limit") ?
                  <ShareOutlinedIcon fontSize="small" style={{ color: "#858C94" }} onClick={shareReport} /> : <ShareOutlinedIcon fontSize="small" style={{ color: "#858C94" }} onClick={upgradePlanToShare} />
                  : <ShareOutlinedIcon fontSize="small" style={{ color: "#858C94" }} onClick={goToPlan} />}
              </span>
              <div className={classes.infoWrapper}>
                <img
                  src={project.company.logo}
                  className={classes.companyLogo}
                />
                <div className={classes.companyInfo}>

                  <Typography
                    align="left"
                    // color="text"
                    brightness="dark"
                    // weight="bold"
                    className={classes.companyName}
                  // variant="h4"
                  >
                    {project.company.name}

                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="text"
                      brightness="main"
                      style={{}}
                      className={classes.captionText}
                    >
                      {getSectorLabel(project.company.sector)}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text"
                      brightness="main"
                      className={classes.captionText}
                    >
                      {getStageLabel(project.company.stage)}
                    </Typography>
                  </div>
                </div>

              </div>
            </div>
            <div className="d-flex">
              <Typography
                variant="body1"
                className={classes.description}
                color="text"
                brightness="main"
              >
                <span style={{ fontWeight: "bold" }}>
                  Description
                </span>
              </Typography>
              {plan ? "" : <LockIcon fontSize="large" className="text-black " />}

              {/* <LockIcon fontSize="large" className="text-black position-absolute " style={{left:"145px", top:"120px"}}/> */}
            </div>
            <Typography
              variant="body1"
              className={classes.description1}
              color="text"
              brightness="main"
            >

              <span style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{
                __html: project.company.description.length > 150 ? project.company.description.substring(0, 150) + "... " : project.company.description,
              }}></span>
              {/* <span style={{ fontSize: "12px" }}>
            {project.company.description}
          </span> */}
            </Typography>
            <Grid container spacing={3} className={classes.grid}>
              <Grid item>
                {getScoreText(project)}

              </Grid>
              <Grid item>
                <Typography variant="body1" color="text" brightness="main">
                  <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {/* {project.sectionStats.overall.numComments} */}
                    {project.likesCount}
                  </span>{" "}
                  <span style={{ fontSize: "12px" }}>
                    Likes
                  </span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="text" brightness="main">
                  <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {project.sectionStats.overall ? project.sectionStats.overall.numComments : 0}
                  </span>{" "}
                  <span style={{ fontSize: "12px" }}>
                    Insights
                  </span>
                </Typography>
              </Grid>
            </Grid>
          {/* </Link> */}

          {/* <div style={{ position: "absolute", top: 8, right: 8 }}>
        <ReportMenu project={project} />
      </div> */}
      </div>
        </Paper>
   
    );
  }else{
    return (
      <Paper variant="outlined" className={classes.paper}>
        <Link
          to={filter === 'draft'? `/app/create/${project.id}` :`/app/reports/${project.id}`}
          style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.wrapper}>
            <span className={classes.interactions}>
              {/* <span onClick={addToFav(project.id)}> */}
              { filter === 'draft'?"":
                project.likes === true ? (
                  plan ? ((plan.shareProject != plan.sharedProject && plan.shareProject >= plan.sharedProject) || plan.shareProject == "no-limit") ? <FavoriteIcon onClick={removeToFav} fontSize="small" /> :
                    <FavoriteIcon fontSize="small" onClick={upgradePlanToShare} /> :
                    <FavoriteIcon fontSize="small" onClick={goToPlan} />
  
  
                ) : (
                  plan ? ((plan.shareProject != plan.sharedProject && plan.shareProject >= plan.sharedProject) || plan.shareProject == "no-limit") ?
                    <FavoriteBorderOutlinedIcon onClick={(e) => { addToFav(e, project.id) }} fontSize="small" /> :
                    <FavoriteBorderOutlinedIcon fontSize="small" onClick={upgradePlanToShare} /> :
                    <FavoriteBorderOutlinedIcon fontSize="small" onClick={goToPlan} />
  
                )
              }
              {filter === 'draft'?"": plan ? ((plan.shareProject != plan.sharedProject && plan.shareProject >= plan.sharedProject) || plan.shareProject == "no-limit") ?
                <ShareOutlinedIcon fontSize="small" style={{ color: "#858C94" }} onClick={shareReport} /> : <ShareOutlinedIcon fontSize="small" style={{ color: "#858C94" }} onClick={upgradePlanToShare} />
                :
                <>
                  {console.debug("Hello")}<ShareOutlinedIcon fontSize="small" style={{ color: "#858C94" }} onClick={goToPlan} /></>}
            </span>
            <div className={classes.infoWrapper}>
              <img
                src={project.company.logo}
                alt="company logo"
                className={classes.companyLogo}
              />
              <div className={classes.companyInfo}>
  
                <Typography
                  align="left"
                  // color="text"
                  brightness="dark"
                  // weight="bold"
                  className={classes.companyName}
                // variant="h4"
                >
                  {project.company.name}
  
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="caption"
                    color="text"
                    brightness="main"
                    style={{}}
                    className={classes.captionText}
                  >
                    {getSectorLabel(project.company.sector)}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text"
                    brightness="main"
                    className={classes.captionText}
                  >
                    {getStageLabel(project.company.stage)}
                  </Typography>
                </div>
              </div>
  
            </div>
          </div>
          <Typography
            variant="body1"
            className={classes.description}
            color="text"
            brightness="main"
          >
            <span style={{ fontWeight: "bold" }}>
              Description
            </span>
          </Typography>
          {/* {plan ? "" : <LockIcon fontSize="large" className="text-black " />} */}
  
          <Typography
            variant="body1"
            className={classes.description1}
            color="text"
            brightness="main"
          >
            <span style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{
              __html: project.company.description.length > 150 ? project.company.description.substring(0, 150) + "... " : project.company.description,
            }}></span>
            {/* <span style={{ fontSize: "12px" }}>
              {project.company.description}
            </span> */}
          </Typography>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item>
              {getScoreText(project)}
  
            </Grid>
            <Grid item>
              <Typography variant="body1" color="text" brightness="main">
                <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                  {/* {project.sectionStats.overall.numComments} */}
                  {project.likesCount}
                </span>{" "}
                <span style={{ fontSize: "12px" }}>
                  Likes
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" color="text" brightness="main">
                <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                  {project.sectionStats.overall ? project.sectionStats.overall.numComments : 0}
                </span>{" "}
                <span style={{ fontSize: "12px" }}>
                  Insights
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Link>
        <SubscriptionCard open={openPlan} setOpenPlan={setOpenPlan} />
        {/* <div style={{ position: "absolute", top: 8, right: 8 }}>
          <ReportMenu project={project} />
        </div> */}
      </Paper>
  
    );
  }


}

export { ProjectCard };
