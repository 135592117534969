import React, { useEffect, useState } from "react";
import { Checkbox, Divider } from "antd";
import { Info } from "@material-ui/icons";
import { useFormikContext } from "formik";
import { DatePicker, Checkbox as FCheckbox } from "formik-antd";
import moment from "moment";
import { FormInfo } from "../../../../model";
import {
  Typography,
  HtmlTooltip,
} from "../../../../components/Wrappers/Wrappers";
import { Select } from "../../../../components/Wrappers/FormikWrappers";
import { PercentSlider } from "./PercentSlider";
import useStyles from "./styles";
import FadeIn from "react-fade-in";
import classnames from "classnames";
import { sectionOptions } from "../data";
import { FileUploadField } from "../Fields/FileUpload";
import {info} from "../infos";
//TODO: add preferredWeights
// import { fetchPreferredWeights } from "../../../../utils/localstorage";
import {
  Grid,
} from "@material-ui/core";
import CustomiseTooltip from "../../../../components/Tooltip/CustomiseTooltip";
import { useSelector } from "react-redux";
const { Option } = Select;
const { RangePicker } = DatePicker;
interface SectionProps {
  section: string;
  displayUpload: boolean;
}

function FileUpload({ section, displayUpload }: SectionProps) {
  const classes = useStyles();
  const [allSectionsSelected, setallSectionsSelected] = useState(true);
  const [uploadButtonState, UploadButtonF] = useState(false);
  const profile = useSelector((state: RootState) => state.firebase.profile);
  const { values, errors, touched, setFieldValue } = useFormikContext<
    FormInfo
  >();
  // console.debug("chvewhvsbcs ewicwbkwibwe wiuwejkwk", values)
  const [weight, setweight] = useState<number>(100.0);
  const today = new Date()
  useEffect(() => {
    const newWeights = values.sectionWeights;
    // console.debug("newWeights", newWeights)
    const totalWeight = Number.parseFloat(
      `${Object.values(newWeights).reduce((prev, next) => prev + next, 0) ?? 0}`
    );
    // console.debug("totalWeight", totalWeight)
    setweight(totalWeight);
  }, [values, setFieldValue]);

  useEffect(() => {
    if (allSectionsSelected == true) {
      const _sections = sectionOptions
      .filter((v) => v.weight !== 0)
      .map((v) => ({ "value": v.value, "weight": v.weight }));
  
      // setFieldValue("sections", _sections, true);
      console.debug("allSectionsSelected", _sections)
      recalculateWeights(_sections);
      const _sectionsStat = sectionOptions
      .filter((t) => t.weight !== 0)
      .map((d) => (d.value));
      setFieldValue("sections", _sectionsStat, true);
    } else {
      setFieldValue("sections", [], true);
      recalculateWeights([]);
    }
  }, [allSectionsSelected == true]);


  useEffect(() => {
    const endDate= new Date(profile.currentPlan.planExpireDate)
   if(profile.currentPlan){
    const date=[moment(today).toISOString(), moment(endDate).toISOString()]
    setFieldValue("dateRange",date, true)
   }
   
  }, [profile]);



  // console.debug("chvewhvsbcs ewicwbkwibwe wiuwejkwk", profile)

  function recalculateWeights(sections: string[]) {
    // console.debug("sections.weight", sections)
    // console.debug("sections.weight",values.sectionWeights)
    // const newWeights = _.pick(values.sectionWeights, sections);
     const newSection= sectionOptions
     .filter((v) => v.weight !== 0)

    const newWeights = newSection
    .reduce((acc, obj) => {
      acc[obj.value] = obj.weight;
      return acc;
    }, {});
    // console.error("DPMASDOPFMAPDOFMAS");
    if (sections.length === newSection.length) setallSectionsSelected(true);
    else setallSectionsSelected(false);
    // console.debug("setallSectionsSelected", newWeights);
    setFieldValue("sectionWeights", newWeights, true);
  }


  // console.debug("endDate", profile.currentPlan.planExpireDate)
  // console.debug("startDate", today)
  // console.debug("ALlElement", sectionOptions)

  return (
    <div className={classes.root}>
      <div className={classes.cardContainer}>
        <FadeIn>
          <Typography variant="h4" className={classes.spacing}>
            Project Duration
          </Typography>
          {errors.dateRange && touched.dateRange && (
            <Typography variant="caption" color="secondary">
              {errors.dateRange}
            </Typography>
          )}
          <RangePicker
            name="dateRange"
            value={[
              moment(today), // Start date
              moment(profile.currentPlan.planExpireDate), // End date
            ]}
            className={classes.spacing}
            disabled={true}
            disabledDate={(m) => m.isBefore(moment().subtract(1, "days"))}
          />
          <Typography
            variant="h4"
            className={classnames(
              classes.spacingTop,
              classes.spacing,
              classes.center
            )}
          >
            Sections to Analyse
            <HtmlTooltip
              title={info.pageInfo}
              style={{ marginLeft: 4 }}
              key="main-tooltip"
            >
              <Info fontSize="small" className={classes.info} />
            </HtmlTooltip>
          </Typography>
          {touched.sections && errors.sections && (
            <Typography variant="caption" color="secondary">
              {errors.sections}
            </Typography>
          )}
          <Checkbox
            checked={allSectionsSelected}
            disabled={true}
            // onChange={(e) => _selectAllSections(e.target.checked)}
          >
            <Typography variant="body" weight="bold">
              Select all sections
            </Typography>
          </Checkbox>
          <Select
            name="sections"
            mode="multiple"
            style={{ width: "100%", marginTop: 10 }}
            placeholder="Please select the sections on which you wish to perform DD"
            onChange={(value) => {
              recalculateWeights(value as string[]);
            }}
            disabled={true}
          >
            {sectionOptions.map((section, index) => (
              <Option key={index} value={section.value}>
                {section.label}
              </Option>
            ))}
          </Select>
          <Typography className={classes.uploadDoc} variant="h5" >
            Data Room Document Upload
          </Typography>
         {/* {console.debug("FileUploadField:singlePdf", values.singlePdf)} */}
          <div className={classes.singleFile}>
            {!!values.sections.length && (
              <FCheckbox
                name="singlePdf"
                style={{ marginTop: 10, marginBottom: 10, marginRight: "2rem" }}
                // value={values.singlePdf}
              >
                <Typography variant="body" weight="bold">
                  Upload 1 document for all sections
                </Typography>
              </FCheckbox>
              
            )}
            {values.singlePdf && <FileUploadField oneDoc="OneDocument" UploadButtonF={UploadButtonF} uploadButtonState={uploadButtonState} fieldName="singlePdfLink" />}
            
          </div>
          <div>
            {(!!values.sections.length) && (<div className={classes.weightCard}>
              <Grid container>
                {/* <Grid item xs={12}> */}
                <Grid item xs={!values.singlePdf ? 6 : 3}  className={classes.leftAlign}>
                  {!values.singlePdf && (<FCheckbox
                    checked
                    name="multiplePdf"
                    style={{ textAlign: "left" }}
                    disabled
                  >
                    <Typography variant="body" weight="bold">
                      Multiple Files
                    </Typography>
                  </FCheckbox>)}
                </Grid>
                <Grid item xs={!values.singlePdf ? 2: 2} className={!values.singlePdf ? classes.rightAutoAlign : classes.rightAlign}>
                  <Typography>Weight
                  <CustomiseTooltip className={classes.info} title={info.weight} />
                    {/* <HtmlTooltip
                    title={tooltipContent}
                      // title={`This is the weight placed on the ${values.sections} section. The higher the weight, the more impact this score has on the Overall score. All sections must add up to 1.`}
                      key={values.sections}
                    >
                      <Info fontSize="small" color="primary" />
                    </HtmlTooltip> */}
                    </Typography>
                </Grid>
                {/* </Grid> */}
              </Grid> </div>)}
             
            {/* {Array.from(values.sections).map((section) => ( */}
            {Array.from(sectionOptions) .filter((v) => v.weight !== 0).map((section, index) => (

              <div key={index}>
               {/* {console.debug("SectionWidget:section", section)} */}
              <SectionWidget
                // key={section}
                section={section.value}
                weight={section.weight}
                // sectionsValue={values.sections}
                displayUpload={!values.singlePdf}
              />
              </div>
            ))}
            {values.sections.length > 0 && (
              <>
                <Divider />
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Typography
                    weight="bold"
                    htmlColor={weight !== 100 ? "red" : "black"}
                    variant="h6"
                  >

                    Total weight: {weight?.toFixed(1)}/100.0%
                    <HtmlTooltip
                      title="Total must equal 100%"
                    >
                      <Info fontSize="small" className={classes.info} />
                    </HtmlTooltip>
                  </Typography>
                </div>
              </>
            )}
          </div>
        </FadeIn>

      </div>
    </div>
  );
}

interface SectionProps {
  section: string;
  displayUpload: boolean;
  weight:number;
}

function SectionWidget({ section, displayUpload, weight }: SectionProps) {
  const [noFile, setNoFile] = useState(false);
  const classes = useStyles();
  const [uploadButton, setUploadButton] = useState(false);

  return (
    <div key={section} className={classes.singleFile}>
      <Grid container className={classes.baseLine}>
        <Grid item xs={4} >
          <div className={classes.sectionFile} >
            <Typography style={{ marginRight: 4 }}>
              {sectionOptions.find((s) => s.value === section)?.label}
            </Typography>
            <HtmlTooltip
              key={section}
              style={{ maxWidth: "600px" }}
              title={
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    Information upload considerations:
                  </span>{" "}
                  {info[section]}
                </p>
              }
            >
              <Info fontSize="small" className={classes.info} />
            </HtmlTooltip>
          </div>
        </Grid>
        {displayUpload && <Grid item xs={3} md={(displayUpload && !uploadButton) ? 2 : 5} >


          {(displayUpload && !noFile) && <> <FileUploadField UploadButtonF={setUploadButton} uploadButtonState={uploadButton} fieldName={`pdfLinks.${section}`} /> </>}
        </Grid>}
        {/* {displayUpload && <Grid item xs={3} > */}

          {(displayUpload && !uploadButton) && <Grid item xs={3}><Checkbox
            checked={noFile}
            // style={{ marginLeft: "2rem" }}
            onChange={(e) => setNoFile(e.target.checked)}
          >
            <Typography variant="body" weight="bold">
              No File available
            </Typography>
          </Checkbox>
          </Grid>
          }
        {/* </Grid>} */}
        <Grid item xs={2} >

          <div
            className={classes.weightText}
          >

            <PercentSlider key={section} section={section} weight={weight} />

          </div>
        </Grid>

      </Grid>

    </div >
  );
}

export default FileUpload;
