import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";
import { Invitation } from "./invitation";
import { IResponse } from "./responses";
import { firestore } from "firebase";
import { ReactNode } from "react";

export interface Section {
  [x: string]: ReactNode;
  avgRating: number;
  numComments: number;
  invited: number;
  completed: number;
  views: number;
  previousRank?: number;
}

export interface CompanySummary {
  id?: string;
  name: string;
  logo: string;
  sector: string;
  location?: string;
  description: string;
  stage: string;
  rapidDescription?: string;
  website: string;
  investmentLink?: string;
  contactEmail?: string;
}

export interface newScoringAssist {
  statement?: string;
  DTP?: string;
  painPoints?: string;
  marketDynamics?: string;
  solutionStatement?: string;
  USPB?: string;
  PKF?: string;
  useCases?: string;
  competitiveLandscape?: string;
  identifyCompetitors?: string;
  competitiveAdvantage?: string;
  MOAT?: string;
  marketSizeTam?: string;
  MGP?: string;
  marketOpportunity?: string;
  marketTrends?: string;
  revenueStreams?: string;
  costStructure?: string;
  salesChannelsCustomer?: string;
  CAS?: string;
  fundingAmount?: string;
  useofFunds?: string;
  valuation?: string;
  financialProjections?: string;
  targetAudience?: string;
  userProfiles?: string;
  marketingStrategy?: string;
  salesStrategy?: string;
  MT?: string;
  marketValidation?: string;
  strategicPartnerships?: string;
  futurePlans?: string;
  tokenUtility?: string;
  tokenDistribution?: string;
  tokenSale?: string;
  incentives?: string;
}

export interface Project {
  id: string;
  isDemo: boolean;
  company: CompanySummary;
  requester: string;
  startDate: firestore.Timestamp;
  endDate: firestore.Timestamp;
  sections: string[];
  selectedEmails:string[];
  sectionWeights: Record<string, number>;
  scoringTypes:string[];
  sectionStats: Record<string, Section>;
  emails:string[];
  emailSections: Record<string, string>;
  pdfLinks: Record<string, string>;
  sendProjectToMe?:boolean;
  imageLinks?: Record<string, string>;
  technical?: Record<string, number>;
  scoringAssist?: newScoringAssist;
  privacy: number;
  needExpertScore:string;
  likes?: boolean;
  shared?: boolean;
  crypto: boolean;
  previousRank?: number;
  singlePdf?:boolean;
  likesCount: number;
  activeStatus:boolean;
  TechnicalAnalysis: TechnicalAnalysis;
  cid?: string;
  userId?:number;
  
  
}

export interface Report {
  project: Project;
  responses: IResponse[];
  team?: Record<string,any>[];
  invitations: Invitation[];
  rank: number;
}

export interface TechnicalAnalysis {
  CompetitiveLandscape?: number;
  SalesChannels?: number;
  MarketingPartners?: number;
  SocialNetwork?: number;
  marketSize?:number;
  industryCagr?:number;
  marketRisk?: number;
  fundFromWhere?: number;
  burnRate?: number;
  CapitalSize?: number;
  ExternalFundYear?: number;
}

export interface ProjectState {
  allProjects: Project[];
  filter:string | null;
  draftProjects: Project[];
  focusedReport?: Report;
  fetching: boolean;
  fetchError?: Error | string;
  creating: boolean;
  createError?: Error | string;
  sharing: boolean;
  shared: boolean;
  shareError?: Error | string;
  deleting: boolean;
  deleted: boolean;
  deleteError?: Error | string;
  addFavouriteError?: Error | string;
  removeFavouriteError?: Error | string;
  nextPageToken: string | null; // For pagination
  hasMore: boolean;             // To track if more projects are available
  previousFilter: string | null| undefined;
  previousSortBy:string | null| undefined;
  previousSortType:string | null| undefined;
}



export enum ProjectActions {
  CREATE = "CREATE_PROJECT",
  CREATE_SUCCESS = "CREATE_PROJECT_SUCCESS",
  CREATE_ERROR = "CREATE_PROJECT_ERROR",
  FETCH = "FETCH_PROJECT",
  FETCH_DRAFT = "FETCH_DRAFT",
  FETCHED_REPORT = "FETCHED_REPORT",
  FETCHED_ALL = "FETCHED_ALL_PROJECTS",
  FETCHED_DRAFT ='FETCHED_DRAFT',
  FETCH_ERROR = "FETCH_PROJECT_ERROR",
  DELETE = "SHARE_PROJECT",
  DELETED_PROJECT = "DELETED_PROJECT",
  DELETE_ERROR = "SHARE_ERROR",
  SHARE = "SHARE_PROJECT",
  SHARED_PROJECT = "SHARED_PROJECT",
  SHARED_REPORT = "SHARED_REPORT",
  SHARE_ERROR = "SHARE_ERROR",
  RESET_MODAL = "RESET_PROJECT_MODAL",
  ADD_FAVOURITE = "ADD_FAVOURITE_PROJECT",
  ADD_FAVOURITE_ERROR = "ADD_FAVOURITE_PROJECT_ERROR",
  REMOVE_FAVOURITE = "REMOVE_FAVOURITE_PROJECT",
  REMOVE_FAVOURITE_ERROR = "REMOVE_FAVOURITE_PROJECT_ERROR",
}

interface ProjectActionType<T, P = {}> {
  type: T;
  payload?: P;
}

export interface FetchProjectsPayload {
  filter?:string;
  sortBy?:string;
  sortingType?:string;
  projects?: Project[]; // Optional, because not all actions will provide projects
  nextPageToken?: string | null; // Optional
  hasMore?: boolean; // Optional
  loading?: boolean; // Optional
  error?: Error | string; // Optional
  previousFilter?:string| null | undefined
  previousSortBy?:string| null | undefined
  previousSortType?:string| null | undefined
  
}

// For actions that involve errors only
export interface FetchErrorPayload {
  error: Error | string; // Define specific error payload
}


export type ProjectAction =
  | ProjectActionType<typeof ProjectActions.CREATE>
  | ProjectActionType<typeof ProjectActions.CREATE_SUCCESS>
  | ProjectActionType<typeof ProjectActions.CREATE_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.FETCH>
  | ProjectActionType<typeof ProjectActions.FETCHED_REPORT, Report>
  | ProjectActionType<typeof ProjectActions.FETCHED_ALL, FetchProjectsPayload>
  | ProjectActionType<typeof ProjectActions.FETCH_DRAFT>
  | ProjectActionType<typeof ProjectActions.FETCHED_DRAFT, Project[]>
  | ProjectActionType<typeof ProjectActions.FETCH_ERROR, FetchErrorPayload> // Updated
  | ProjectActionType<typeof ProjectActions.DELETE>
  | ProjectActionType<typeof ProjectActions.DELETED_PROJECT, string>
  | ProjectActionType<typeof ProjectActions.DELETE_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.SHARE>
  | ProjectActionType<typeof ProjectActions.SHARED_PROJECT>
  | ProjectActionType<typeof ProjectActions.SHARED_REPORT>
  | ProjectActionType<typeof ProjectActions.SHARE_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.RESET_MODAL>
  | ProjectActionType<typeof ProjectActions.ADD_FAVOURITE>
  | ProjectActionType<typeof ProjectActions.ADD_FAVOURITE_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.REMOVE_FAVOURITE>
  | ProjectActionType<typeof ProjectActions.REMOVE_FAVOURITE_ERROR, Error | string>;

  export type ProjectThunk = ThunkAction<void, RootState, unknown, ProjectAction>;
  export type ProjectDispatch = ThunkDispatch<RootState, any, ProjectAction>;
    
