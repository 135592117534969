import React, { useEffect, Dispatch, useState, useCallback } from "react";
import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";

import {
	Button,
	Fab,
	Input,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow, Tooltip
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Add as AddIcon, ChevronRight } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { searchString } from "../../actions/search";
import useStyles from "./styles";
import { ProjectCard } from "../../components/ProjectCard";
import { ProjectList } from "../../components/ProjectList";
import { RootState } from "../../reducers";
import { fetchAllProjects, fetchSearchProjects } from "../../actions/project";
import ReactDOM from 'react-dom'
import {
	ProjectDispatch,
	LayoutAction,
	Project,
	SearchAction,
} from "../../model";
import { Typography } from "../../components/Wrappers/Wrappers";
import ListViewToggle from "../../components/ListToggle/ListToggle";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import { firestore, callableFunctions, storage, messaging, app } from "../../repositories/firebase";
import FadeIn from "react-fade-in";
import _ from "lodash";
import { overallWeightedScore } from "../create/components/data";
import { ViewType, SortingType, Filter, ScoreType } from "../../utils/enums";
import {
	getDefaultViewType,
	setDefaultViewType,
} from "../../utils/localstorage";
// import { ethers } from "ethers";
// import Web3 from 'web3';
import techscore from "../../repositories/techscore";
import ProjectGrid from "./ProjectGrid";
import debounce from "lodash.debounce";
// import {messaging, app} from "../repositories/firebase";
import html2pdf from 'html2pdf.js';
import ReactDOMServer from 'react-dom/server';
import { showPastDueModal, showUpgradeModal } from "../../actions/layout";
import DemoComponent from "../create/components/companyDetailPDF";
import { CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import ProblemDefinition from "../create/components/ProblemDefinition";
import SubscriptionCard from "../create/components/plan";
// import { firestore } from "../../repositories/firestore";

declare var window: any


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);

const containerStyle = {
	backgroundColor: '#002060',	
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '500vh',
}

const getTotalNumberProjectOfUser = callableFunctions.httpsCallable('getTotalNumberProjectOfUser');

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#F2F7FF",
	},
}))(TableCell);
const LightTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);
function getDefaultSorting(search: string): Filter {
	const sorting = new URLSearchParams(search).get("sort");
	switch (sorting) {
	  case "myLikes":
		return Filter.likes;
	  case "myShare":
		return Filter.shares;
	  case "myUploads":
		return Filter.uploads;
	  case "draft":
		return Filter.draft;
	  default:
		return Filter.overall;
	}
  }

const pageStyle = {
	backgroundColor: '#002060',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minHeight: '200vh',
	flexDirection: 'column',
};

const imageStyle = {
	width: '100%',
	height: 'auto',
};

function Dashboard(props) {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [scoreSort, setScoreSort] = useState(ScoreType.default);
	const keys = [
		{ id: 1, label: "" },
		{ id: 2, label: "" },
		{ id: 3, label: "Name" },
		{ id: 4, label: "Industry" },
		{ id: 5, label: "Stage" },
		{ id: 6, label: "Description" },
		{ id: 7, label: "Experts" },
		{ id: 8, label: "Comments" },
		{ id: 9, label: scoreSort + " Score" },
		{ id: 10, label: "" },
	];
	const { allProjects, fetching, nextPageToken, hasMore } = useSelector((state: RootState) => state.project);
	// const project = useSelector((state: RootState) => state.project);
	const [userCreatedProject, setUserCreatedProject] = useState([])
	// const projectCollection = firestore.collection("project");
	const profile = useSelector((state: RootState) => state.firebase.profile);
	const [projectData, setProjectData] = useState<Project[]>([]);
	// const allProjects = profile.currentPlan ? project.allProjects : projectPerPlan(userCreatedProject)
	const search = useSelector((state: RootState) => state.search);
	const walletOpen = useSelector((state: RootState) => state.metamask.walletOpen);
	const userData = useSelector((state: RootState) => state.firebase.auth);
	const user = useSelector((state: RootState) => state.firebase.auth);
	const userCollection = firestore.collection("users");
	const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
	const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
	const dispatch = useDispatch<ProjectDispatch>();
	const [sortingType, setsortingType] = useState(SortingType.highToLow);
	const [filter, setFilter] = useState<Filter | null>(null)
	const [weight, setWeight] = useState();
	const [pdfUrl, setPdfUrl] = useState('');
	const [openPlan, setOpenPlan] = useState(false)
	const [limit, setLimit]=useState(12)
	

	console.debug("projectlist:164", props.headerSearch)

	const dispatchSearchProject = useCallback(
		debounce((query: string) => {
		  console.debug("projectlist:164", query); // Debug log
		  dispatch(fetchSearchProjects(limit, null, query));
		}, 300), // Adjust debounce delay as needed
		[dispatch]
	  );
	
	  // Effect to run the search whenever `headerSearch` changes
	  useEffect(() => {
		if(props.headerSearch == ""){

		}else{ 
		dispatchSearchProject(props.headerSearch);
		}
	  }, [props.headerSearch, dispatchSearchProject]);

	function _byOverallRanking(a: Project, b: Project): number {
		return overallWeightedScore(a, weight) - overallWeightedScore(b, weight);
	}
	// const [sortedProjects, setsortedProjects] = useState<Project[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [viewType, setViewType] = useState<ViewType>(getDefaultViewType());

	useEffect(() => {
		setFilter(getDefaultSorting(location.search));
	}, [location]);

	function sortingFilter(rankedBy, sortingType){
		switch (sortingType){
			case SortingType.highToLow:
	  dispatch(fetchAllProjects(limit, null, filter, rankedBy, SortingType.highToLow)); // Fetch with technical sortingbreak
			break;
			case SortingType.lowToHigh:
	dispatch(fetchAllProjects(limit, null, filter, rankedBy, SortingType.lowToHigh));
			break;
	case SortingType.nameAsc:
	dispatch(fetchAllProjects(limit, null, filter, rankedBy, SortingType.nameAsc));
			break;
	case SortingType.nameDesc:
	dispatch(fetchAllProjects(limit, null, filter, rankedBy, SortingType.nameDesc));
			break;
			case SortingType.dateAsc:
	dispatch(fetchAllProjects(limit, null, filter, rankedBy, SortingType.dateAsc));
			break;
			case SortingType.dateDesc:
	dispatch(fetchAllProjects(limit, null, filter, rankedBy, SortingType.dateDesc));
			break;
	   default:
		dispatch(fetchAllProjects(limit, null, filter, rankedBy, SortingType.highToLow)); 
		break	
		}
	}

	useEffect(() => {
		if (filter) {
		switch (filter) {
		  case Filter.uploads:
			switch (scoreSort) {
				case ScoreType.technical:
				sortingFilter(ScoreType.technical,sortingType)
				  break;
				case ScoreType.expert:
					sortingFilter(ScoreType.expert,sortingType)
				  break;
				case ScoreType.rapid:
					sortingFilter(ScoreType.rapid,sortingType)
				  break;
				case ScoreType.default:
					sortingFilter(ScoreType.default,sortingType)
			    break
			  }
			  break;
			  
		  case Filter.likes:
			switch (scoreSort) {
				case ScoreType.technical:
					sortingFilter(ScoreType.technical,sortingType)
				  break;
				case ScoreType.expert:
				  sortingFilter(ScoreType.expert,sortingType)
				  break;
				  case ScoreType.rapid:
					sortingFilter(ScoreType.rapid,sortingType)
				  break;
				case ScoreType.default:
				 sortingFilter(ScoreType.default,sortingType)
				  break;
			  }
		   break;

		   case Filter.shares:
			switch (scoreSort) {
				case ScoreType.technical:
					sortingFilter(ScoreType.technical,sortingType)
				  break;
				case ScoreType.expert:
				  sortingFilter(ScoreType.expert,sortingType)
				  break;
				  case ScoreType.rapid:
					sortingFilter(ScoreType.rapid,sortingType)
				  break;
				case ScoreType.default:
				 sortingFilter(ScoreType.default,sortingType)
				  break;
			}
			break;
		   case Filter.draft:
			dispatch(fetchAllProjects(limit, null, Filter.draft, null));
			break;
		  // Add more cases for other filters if needed
	  
		  default:dispatch(fetchAllProjects(limit, null, Filter.overall, scoreSort, sortingType))
			break; 
		}

	}
	  }, [dispatch, limit, filter, scoreSort, sortingType, user.uid]);

	


	async function fetchData() {
		try {
			const response = await getTotalNumberProjectOfUser();
			if (response.data) {
				let data = response.data
				setUserCreatedProject(data.userProjectData.map(p => p.projectId))

			}
		} catch (error) {
			// Handle any errors that occurred during the axios request
			console.error('Error:', error);
		}
	}

	function projectPerPlan(allProjects, projectId) {
		const resultArray = allProjects.filter((element) => !projectId.includes(element.id));
		return resultArray;
	}


console.debug("projectData:sortingType", allProjects)
	useEffect(() => {
		  
		setProjectData(allProjects);

	}, [allProjects, filter, sortingType, search.searchString, scoreSort]);


	useEffect(() => {
		dispatchSearch(searchString(searchQuery));
	}, [dispatchSearch]);


	const handleLoadMore = () => {
		if (!fetching && hasMore) {
			// if(filter == null){
		//   dispatch(fetchAllProjects(limit, nextPageToken)); // Fetch the next page using nextPageToken
		// 	}else{
				dispatch(fetchAllProjects(limit, nextPageToken, filter, scoreSort, sortingType));
		// 	}
		}
	  };
	
	function openPlanPage(e: React.MouseEvent): void{
		e.preventDefault();
		setOpenPlan(true)
	}
	const userName = user.displayName?.split(" ")[0] ?? "there!";

	return (
		<>
			<Fab
				className={classes.fab}
				// color="primary"
				variant="extended"
				aria-label="add"
				onClick={goToCreate}
			>
				<AddIcon />
				New Project
			</Fab>
			<>

				<PageTitle
					noMargin
					title={
						new Date().getHours() > 12
							? "Good afternoon " + userName
							: "Good morning " + userName
					}
					extra={
						<div
						style={{
							display: "flex",
							// flexDirection: "column",
							// alignItems: "flex-end",
						}}
					>
						
					{filter == "draft" ? "" : <div className={classes.rankedContent}>
							<Typography className={classes.typo} variant="h3" size="sm">
								Ranked by:
							</Typography>
							<LightTooltip title="Overall" placement="top">
								<div className={classes.circleRank} onClick={(e) => {
									setScoreSort(ScoreType.default);
									
								}
								}><img src="/Buttons-02.png" className={scoreSort == "overall" ? "" : classes.disabledImage} /></div>
							</LightTooltip>
							<LightTooltip title="Expert" placement="top">
								<div className={classes.circleRank} onClick={(e) => {
									setScoreSort(ScoreType.expert);
								}

								}><img src="/Buttons-04.png" className={scoreSort == "expert" ? "" : classes.disabledImage} /></div>
							</LightTooltip>
							<LightTooltip title="Technical" placement="top">
								<div className={classes.circleRank} onClick={(e) => {
									setScoreSort(ScoreType.technical);
								}
								}><img src="/Buttons-03.png" className={scoreSort == "technical" ? "" : classes.disabledImage} /></div>
							</LightTooltip>
							<LightTooltip title="i3D Rapid" placement="top">
								<div className={classes.circleRank} onClick={(e) => {
									setScoreSort(ScoreType.rapid);
								}
								}><img src="/Buttons-01.png" className={scoreSort == "rapid" ? "" : classes.disabledImage} /></div>
							</LightTooltip>
						</div>}
						{filter == "draft" ? "" : <div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
							}}
						>
							<FormControl variant="filled">
								{/* <InputLabel id="sort">Sort By</InputLabel> */}
								<Select
									id="sort"
									label="Sorting"
									value={sortingType}
									style={{
										height: 40,
										backgroundColor: "#F4F6F9",
									}}
									onChange={(e) => {
										// if (walletOpen) {
										// 	history.push('/connect')
										// }
										setsortingType(
											e.target.value as SortingType
										)
									}
									}
									input={
										<Input
											classes={{
												input: classes.selectInput,
											}}
										/>
									}
								>
									<MenuItem value={SortingType.nameAsc}>
										Sort/(A-z)
									</MenuItem>
									<MenuItem
										value={SortingType.nameDesc}
									>
										Alphabetical (z-A)
									</MenuItem>
									<MenuItem value={SortingType.dateDesc}>
										Newest - Oldest
									</MenuItem>
									<MenuItem value={SortingType.dateAsc}>
										Oldest - Newest
									</MenuItem>
									<MenuItem value={SortingType.highToLow}>
										Score High - Low
									</MenuItem>
									<MenuItem
										value={SortingType.lowToHigh}
									>
										Score Low - High
									</MenuItem>
									{/* <MenuItem value={SortingType.sector}>
										Sector
									</MenuItem> */}
								</Select>
							</FormControl>
							<ListViewToggle
								checked={viewType === ViewType.list}
								onChange={(_, checked) => {
									// if (walletOpen) {
									// 	history.push('/connect')
									// }
									const viewType = checked
										? ViewType.list
										: ViewType.grid;
									setViewType(viewType);
									setDefaultViewType(viewType);
								}}
							/>
						</div>}
					</div>
					}
				
				/>

             {<div className={classes.container}>
					{viewType === ViewType.grid ? (
						<ProjectGrid scoreSort={scoreSort} fetching={fetching} hasMore={hasMore} handleLoadMore={handleLoadMore} sortedProjects={projectData} filter={filter} walletOpen={walletOpen} />
					) : (
						<TableContainer>
							<Table stickyHeader className="mb-0">
								<TableHead>
									<TableRow>
										{keys.map((key, index) => (
											<StyledTableCell key={index} className={classes.labelHead} >{key.label}</StyledTableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{projectData.filter((v) =>
										v.company.name
											.toLowerCase()
											.includes(search.searchString.toLowerCase())
									).map((project, index) => {
								
											return <ProjectList filter={scoreSort} key={index} project={project} pageType={'dashboard'}  />

									})}
		  
								</TableBody>
							</Table>
							{hasMore && (
                            <div style={{ textAlign: "center", marginTop: "20px" }}>
                              <Button
                                onClick={handleLoadMore}
                                disabled={fetching}
                                variant="contained"
                                color="primary"
                                >
                             {fetching ? "Loading..." : "Load More"}
                            </Button>
                            </div>
                            )}
						</TableContainer>
					)}
				</div>}
			</>
			<SubscriptionCard open={openPlan} setOpenPlan={setOpenPlan}/>
		</>
	);

	function goToCreate(e: React.MouseEvent): void {
		e.preventDefault();
        // return history.push("/create")

		if (profile.currentPlan) {
			let plan = profile.currentPlan
			if (plan.uploadProject != plan.uploadedProject && plan.uploadProject >= plan.uploadedProject) {
				return history.push("/create");
			} else {
				return dispatchLayout(showUpgradeModal());
			}
		} else {
			openPlanPage(e)
			
		}

	}

}



export default Dashboard;